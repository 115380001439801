<template>
    <!-- <div @mousedown="ver_se_logado()"> -->
      <div v-if="can_show">


        <!-- janela_modal_tabela: {{janela_modal_tabela}} <br/> -->
        <!-- {{items_tabela_categoria_operacao}} -->
        <!-- {{items_tabela_categoria}} -->
        <!-- cliente_selecionado: {{cliente_selecionado}} <br/><br/> -->
        <!-- {{ obj_reajuste }} -->
        <!-- {{mostrar_somatorias_altura}} -->
        <!-- {{ items_somatorias }} -->
        <!-- item_menu:{{item_menu}} <br/> -->
        <!-- {{souDSi}} -->
        <!-- {{superA}} -->

        <!-- obj_user:{{obj_user}} <br/><br/> -->
        <!-- {{size_bd}} -->
        <!-- {{items_importado}} -->

        <!-- {{voltar_para}} -->
        <!-- {{card_titulo}} -->
        
        <!-- tabela_selecionada:{{tabela_selecionada}}<br/><br/> -->
        <!-- {{referencia}} -->
        <!-- {{modal_edita}} -->
        <!-- {{modal_item_clicou}} -->
        <!-- {{items_meu_sql}} -->
    
        <!-- obj_clicado:{{obj_clicado}}<br/><br/> -->
        <!-- {{items_filtrados}} -->
        <!-- {{selected}} -->
        <!-- {{ qual_janela }} -->
        <!-- item_edita:{{item_edita}} <br/><br/> -->
        <!-- {{ items_header }} -->
        <!-- {{ items_editaveis }} -->
        <!-- {{items_lista}} -->
        <!-- {{  urlLogo }} -->


       <input
        type="file"
        ref="fileInput"
        accept=".txt"
        @input="handleFileUpload"
        style="display: none;"
      />

      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
      
      <v-snackbar
          v-model="snackbar"
          :timeout="3000"
          top
          :color="snack_color"    
      >
      <center>
          {{ snack_text }}
        </center>
      </v-snackbar>



      <template>
      <div class="text-center">
        <v-dialog
          v-model="dialog_img"
          width="500"
        >

          <v-card>
            <v-card-title class="text-h6 grey lighten-3 justify-center">
              <!-- {{item_clicou.NOME}}  -->
              {{dialog_img_titulo}} 
            </v-card-title>

            <v-card-text>
              <v-img
                alt="user"
                :src="dialog_img_url"
                height="30%"
                v-if="(dialog_img_url)"
              >
            </v-img>
            </v-card-text>

            <v-card-actions>

              <v-spacer></v-spacer>

              <v-btn
                color="primary"
                text
                x-large
                @click="dialog_img = false"
              >
                FECHAR
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
      
     <v-dialog v-model="dialog_modal"
      :max-width="modal_width"
      scrollable
     >
      <v-card class=" mx-auto">

        <v-card-title class=" grey lighten-5">
          <h5> {{ modal_titulo }} </h5>

          <v-spacer></v-spacer>
          
          <v-icon
            color="red"
            @click="dialog_modal=!dialog_modal"
          >
            mdi-close
          </v-icon>

        </v-card-title>

        <v-card-text>
          <v-container class="mx-0 px-0 mt-2">
            <v-row class="">
              <v-col
                  class=""
                  v-for="(item, i) in modal_items"
                  :key="i"
                  :cols="(isMobile) ? 12 : item.cols"
                  v-if="parseInt(item.cols)>0"   
              >

              <v-text-field
                dense
                v-model="modal_edita[item.value]"
                :label="item.rule == 'S' ? item.text+' *': item.text"
                :type="item.type"
                v-if="(item.tag == 'v-text')"
                :hint="item.hint"
              ></v-text-field>


              <v-btn
                :color="item.color"
                small
                v-if="(item.tag == 'v-btn')"
                @click="modal_clicou(item.click)"
                >
                {{ item.text }}
                  <!-- <v-icon>
                    {{ item.icon }}
                  </v-icon> -->
              </v-btn>

              </v-col>
              </v-row>
          </v-container>
        </v-card-text>
      </v-card>
  </v-dialog>


  <!-- MENU -->
  <v-dialog
      v-model="dialog_menu"
      max-width="300px"
      scrollable
    >


        <v-card class="mx-auto">

           <v-list >

                <v-subheader class="grey lighten-3">
                  <span v-if="(selecionou_varios)">
                    <h3> ÍTEMS SELECIONADOS: {{ selected.length}} </h3>
                  </span>
                  <span v-else>
                    <h3> [{{ item_menu.CODIGO  }}] &nbsp 
                      {{  (item_menu.NOME) ? item_menu.NOME :''  }}
                      {{  (item_menu.DESCRICAO) ? item_menu.DESCRICAO :''  }}
                      {{  (item_menu.REFERENTE) ? item_menu.REFERENTE :''  }}
                    </h3>
                  </span>
                </v-subheader>

                <v-divider class="mx-0"></v-divider>
                  <span v-for="item in ((selecionou_varios) ? menu_de_tabelas_selecionados : menu_de_tabelas)" :key="item.title">
                    <v-list-item
                          link
                          @click="clicou_menu_de_tabelas(item.click)"
                          v-if="item.type=='menu'"
                        >

                        <v-list-item-icon>
                            <v-icon  :color="item.icon_color"> {{ item.icon }}</v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                              <v-list-item-title v-text="item.title"></v-list-item-title>
                          </v-list-item-content>

                    </v-list-item>
                    
                    <v-divider
                        class="mx-2"
                    ></v-divider>
                </span>


                <v-list-group
                    v-for="item in ((selecionou_varios) ? menu_de_tabelas_selecionados : menu_de_tabelas)"
                    :key="item.title"
                    v-model="item.active"
                    
                    no-action
                    @click.stop="" 
                    v-if="(item.type=='group')"
                >

                    <template v-slot:activator>

                      <v-list-item-icon>
                        <v-icon :color="item.icon_color" small> {{ item.icon }}</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                          <v-list-item-title v-text="item.title"></v-list-item-title>
                      </v-list-item-content>
                    </template>


                    <v-list-item
                      v-for="sub in item.items"
                      :key="sub.title"
                      link
                      @click="clicou_menu_de_tabelas(sub.click)"
                    >

                      <v-list-item-content>
                          <v-list-item-title v-text="sub.title"></v-list-item-title>
                      </v-list-item-content>
                      
                      
                      <v-list-item-icon>
                        <v-icon :color="sub.icon_color"> {{ sub.icon }}</v-icon>
                      </v-list-item-icon>

                    </v-list-item>
                </v-list-group>



            </v-list>
        </v-card>


  </v-dialog> 

      <v-dialog
          v-model="dialog_tabela"
          persistent
          max-width="990"
          scrollable
      >
  
          <v-card
          class=""
          >
          <!-- height="80%" -->
          <v-card-title>
              <span class="text-h5" 
                v-text="(item_edita.CODIGO>0) ? 'Alterar '+ card_titulo +' '+item_edita.CODIGO : 'Novo '+ card_titulo "
              >
              Alterar
             </span>
          </v-card-title>
  
  

              <!-- passo 4 monto os campos editaveis -->
              <!-- :readonly = "readonly" -->
                  <v-card-text class="pa-1">
                    <v-form
                      ref="form"
                      v-model="valido"
                      >
                      <v-container>
                          <v-row >
                              <v-col
                                  class=""
                                  v-for="(item, i) in items_editaveis"
                                  :key="i"
                                  :cols="(isMobile) ? 12 : item.cols"
                                  v-if="parseInt(item.cols)>0"   
                              >




                                  <v-chip
                                  v-if="item.tag == 'v-chip'" :color="item.color" @click="clicou_em(item.click)"
                                  >
                                    {{ item.text }}
                                  </v-chip>

                                  <v-icon  v-if="item.tag == 'v-icon'" :color="item.color" @click="clicou_em(item.click)"
                                  >
                                    {{ item.icon }}
                                  </v-icon>

                                  <v-btn v-if="(item.tag == 'v-btn')" :color="item.color"
                                  @click="modal_clicou(item.click)"
                                  >
                                    {{item.text}}
                                  </v-btn>


                                  <!-- <span v-if="item.tag == 'span'">
                                    <b> {{ item.text }}</b>
                                  </span> -->

                                  <div v-if="item.grupo" class="mt-n7 pb-2">
                                    <b> {{ item.grupo }}</b>
                                  </div>

                                 <!-- COM V-MASK -->
                                  <v-text-field 
                                      dense
                                      v-model="item_edita[item.value]"
                                      :label="item.rule == 'S' ? item.text+' *': item.text"
                                      :type="item.type"
                                      v-if="(item.tag == 'v-text') && (item.vmask)"
                                      @keyup="meu_keyup($event, item.value, item.upper)"
                                      :rules="item.rule == 'S' ? notnullRules: noRules"
                                      :autofocus="i==1"
                                      :hint="item.hint"
                                      v-mask = "getVmask(item.vmask)"
                                  ></v-text-field>
                                  
                                  <!-- SEM V-MASK  acaba dando erro, por isso separei-->
                                  <v-text-field
                                      dense
                                      v-model="item_edita[item.value]"
                                      :label="item.rule == 'S' ? item.text+' *': item.text"
                                      :type="item.type"
                                      v-if="(item.tag == 'v-text') && (!item.vmask)"
                                      @keyup="meu_keyup($event, item.value, item.upper)"
                                      :rules="item.rule == 'S' ? notnullRules: noRules"
                                      :autofocus="i==1"
                                      :hint="item.hint"
                                  ></v-text-field>



                                  <v-combobox
                                      dense
                                      v-model="item_edita[item.value]"
                                      :label="item.text"
                                      v-if="item.tag == 'v-autocomplete'"
                                      :items="item.tag_items"
                                      :rules="item.rule == 'S' ? notnullRules: noRules"
                                      clearable

                                      @keyup="meu_keyup($event, item.value, item.upper)"

                                      :return-object = 'item.return_object'
                                      :item-value='item.item_value'
                                      :item-text='item.item_text'
                                  ></v-combobox>
                                  
                                  
                                  <v-select
                                      :class="[item.class]"
                                      dense
                                      v-model="item_edita[item.value]"
                                      :label="item.text"
                                      v-if="item.tag == 'v-select'"
                                      :items="item.tag_items"
                                      :rules="item.rule == 'S' ? notnullRules: noRules"

                                      item-value='CODIGO'
                                      :item-text='item.tag_item_text'
                                      :clearable = "item.tag_clear == 'S'"
                                      :persistent-hint="item.cols>2"
                                      :hint="item.hint"
                                  >
                                  
                                  <!-- <template slot="selection" v-slot-scope="item"> -->
                                    
                                  <!-- <template slot="selection" v-slot-scope="item"> -->
                                    <template v-slot:label>
                                      <v-tooltip bottom v-if="item.hint">
                                          <template v-slot:activator="{ on, attrs }">
                                            <span
                                                v-bind="attrs"
                                                v-on="on"
                                            > 
                                              <span :class="item.class">
                                                {{ item.text }} 
                                                <!-- {{ item_edita[item.value] }} -->
                                              </span>
                                            </span>

                                          </template>
                                          <span> {{ item.hint }} </span>
                                        </v-tooltip>

                                        <span :class="item.class" v-else>
                                          {{ item.text }} 
                                        </span>
                                    </template>
       
                                </v-select>


                                  <!-- CAMPO ANOTAÇÕES OU OBSERVAÇÃO -->
                                  <v-textarea
                                    v-model="item_edita[item.value]"
                                    :label="item.text"
                                    v-if="item.tag == 'v-textarea'"
                                    :hint="item.hint"
                                    :persistent-hint="item.cols>2"
                                    dense
                                  >
                                  </v-textarea>




                                  <!-- INICIO DIV IMAGEM -->
                                  <div class=""
                                   v-if="item.tag == 'img'"
                                  >
                                  
                                  <v-row dense class="grey lighten-5">
                                    <v-col  class="">
                                      <span class="pb-2">Logo</span>
                                        <v-img
                                        v-if="urlLogo"
                                        max-height="90"
                                        max-width="170"
                                        :src="urlLogo"
                                        contain
                                      >
                                      </v-img>
                                    </v-col>
                                    
                                    <v-col  class="mt-5">
                                      <p class="primary--text"><label for="file_upload" style="cursor: pointer;">Alterar Logo</label></p>
                                      <!-- <p class="primary--text"><label for="file_upload" style="cursor: pointer;">Alterar Logo</label></p> -->
                                      <!-- <p>Remover</p> -->
                                      <v-btn text x-small color="red" for="file_upload"
                                        @click="item_edita[item.value] = '', urlLogo=''"
                                      >
                                        Remover
                                      </v-btn>
                                    </v-col>


                                    </v-row>
                                    <!-- oculto -->
                                    <v-row dense class="grey lighten-3">
                                      <v-col>
                                        <!-- necessario para fazer upload logo -->
                                        <v-file-input
                                          v-model="files_upload"
                                          accept="image/*"
                                          label="Logomarca"
                                          @change="upload_logo(files_upload, session_bd ,'logo',item.value)"
                                          style="display: none"
                                          id="file_upload"
                                        >
                                        <!-- session_bd = nome da pasta onde salvar as imagens..peguei pelo nome do banco de dados -->
                                        </v-file-input>
                                        <!-- necessario para fazer upload logo -->
                                      </v-col>
                                    </v-row>
                                  </div>
                                  <!-- FINAL DIV IMAGEM -->


                                  
                                  
                              </v-col>
                          </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
  
          <v-card-actions>
              <v-btn
                  x-small
                  color="red"
                  text
                  @click="remover_tabela(tabela_selecionada, item_edita, card_titulo), dialog_tabela = false"
                  v-if="pode_novo(tabela_selecionada)"
              >
                Excluir
              </v-btn>
  
              <v-spacer></v-spacer>
  
              <v-btn
                  color="red"
                  text
                  @click="dialog_tabela = false"
              >
                Cancelar
              </v-btn>
              
              &nbsp
  
              <v-btn
                  :disabled="!valido"
                  color="primary"
                  @click="salvar_tabela(tabela_selecionada, item_edita, 'dialog_tabela')"
              >
                Salvar
              </v-btn>
          </v-card-actions>
          </v-card>
      </v-dialog>
  
  
      <v-container>
         <v-row>
            <v-col
              cols="12"
              :md="col_menu"
              class=""
            >
              <v-card
                  class="mx-auto"
              >
                  <v-list         
                    dense
                    nav
                  >
                      <v-list-item-group
                      v-model="selectedItem"
                      color="primary"
                      active-class="blue--text"
                      >
  
                          <v-divider
                              class="mx-0"
                          ></v-divider>
  
                          <!-- passo 2  mostro os items da lista -->
                          <v-list-item
                              v-for="(item, i) in lista_tabelas"
                              :key="i"
                              @click="card_titulo=item.text, referencia=item.referencia, tabela(item.tabela,'')"
                              @mousemove="col_menu=2"
                          >
                          
                              <v-list-item-icon class="pa-0 mr-2" >
                              <v-icon v-text="item.icon" :color="item.color"></v-icon>


                              <v-icon
                                  size="14"
                                  class="mb-5"
                                  color="red"
                                  style="margin-left:-7px"
                                  v-if="(item.novo=='S')"
                              >
                              <!-- mdi-alpha-n-circle  -->
                              mdi-circle
                              </v-icon>

                              </v-list-item-icon>
                              

  
                              <v-list-item-content>
                                <v-list-item-title :class="[item.color+'--text']">
                                  {{ item.text }}
                                  <!-- {{ i }} -->
                                  <!-- <hr/> -->
                                  
                                </v-list-item-title>
                                <v-divider class="mr-0" v-if="(i==3)||(i==6)||(i==9)"></v-divider>
                              </v-list-item-content>

                          </v-list-item>
  
                          <v-divider
                              class="mx-4"
                          ></v-divider>
  
                      </v-list-item-group>
                  </v-list>
              </v-card>    

              <br>

              <!-- SOMENTE SUPERUSUARIOS -->
              <v-card
               class=""
               v-if="superUser"
              >
                <v-card-text>
                  <center>
                  <v-btn
                          color="success"
                          small
                          @click="update_server"
                          target="_blank"
                      >
                      <!-- :href="" -->
                          Atualizar App
                      </v-btn>
                    </center>
                </v-card-text>
              </v-card>
              
              <br/>


            </v-col> 
  
  
            <v-col
             class="grey lighten-5"
             :cols="(isMobile) ? 12 :  12-col_menu"
            >
              <v-card class="mx-auto" v-if="(referencia!='Painel')">

                 <v-card-title
                   class="pa-1 pl-2"
                   v-if="sub_codigo>0"
                  >
                    <!-- <h4 class="primary--text" @click="ultimo_click()" >{{ sub_descricao }} </h4> -->
                    <v-chip
                    color="primary"
                    @click=" voltar_para = sub_descricao.toLowerCase(), ultimo_click()"
                    >
                      {{ sub_descricao }}
                    </v-chip>
                    &nbsp
                    <h5 class="mb-2 grey--text">{{ sub_codigo }} </h5>

                    &nbsp

                    <v-chip
                    color="primary"
                    @click="voltar_para = search_2, ultimo_click()"
                    >
                      VOLTAR
                    </v-chip>

                  </v-card-title>

                  <v-card-title
                    v-if="card_titulo"
                    class="py-0"
                  >
                      <span class="text-h5"> {{ card_titulo }} </span>
                      <span class="red--text mb-2 ml-1"> {{ items_lista.length }} </span>
  
                    <span  v-if="aguardar_refresh">
                    ...
                    </span>
  
                      &nbsp 
                      <v-col
                      cols="12"
                      md="2"
                      >
  
                          <v-text-field
                          dense
                          v-model="search"
                          clearable
                          append-icon="mdi-magnify"
                          label="Procurar"
                          single-line                
                          hide-details
                          @keyup.esc="search='', voltar_para=''"
                          @keyup="search = search.toLowerCase()"
                          >
                          
                          </v-text-field>


                      </v-col>


                      <v-col
                        cols="12"
                        md="3"
                        class=""
                      >
                        <v-row>
                          <v-col cols="6" v-if="(tabela_selecionada=='clientes')||(tabela_selecionada=='assinaturas')">
                            <v-select
                              v-model="tipo_situacao"
                              label="Situação"
                              :items="['TODOS','TESTE','ATIVO','AUSENTE','CANCELADO','CONCLUIDO','PROMOVER']"
                              dense
                              hide-details=""
                              @change="ultimo_click()"
                              >
                              <!-- @change="open_contas(di,df)" -->
                            </v-select>
                          </v-col>
                          
                          <v-col cols="6" v-if="(tabela_selecionada=='clientes')">
                            <v-select
                              v-model="tipo_cliente"
                              label="Tipo"
                              :items="['TODOS','CFC','CFC WEB','CFC DESK','MEDCLINIC','CIVIC','CLINICA','GESTOR','LOCAÇÃO']"
                              dense
                              hide-details=""
                              @change="ultimo_click()"
                              >
                              <!-- @change="open_contas(di,df)" -->
                            </v-select>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col v-if="(tabela_selecionada=='clientes')"
                        cols="4"
                        md="2"
                        class=""
                      >
                        <v-btn
                            color="primary"
                            small
                            @click="update_dados_pagamentos()"
                        >
                            STATUS $
                        </v-btn>
                      </v-col>
  
                      <v-spacer></v-spacer>
  
                      <v-btn
                          color="primary"
                          small
                          @click="nova_tabela('item_edita','dialog_tabela')"
                          v-if="pode_novo(tabela_selecionada)"
                      >
                          Novo
                      </v-btn>

                      <!-- <v-icon
                          color="white"
                          size="22"
                          class="ml-10"
                          style="background-color: #808080!important;"

                      >
                      mdi-whatsapp
                      </v-icon> -->
  
                  
                  </v-card-title>
  
                  <v-card-subtitle>
  
                  </v-card-subtitle>
  
                  <v-card-text class="pa-1" v-if="(card_titulo) && (!dialog_tabela)">
                  <div>
                      <v-row>
                          <v-col class="">
                              <v-card
                                @mouseover="refresh_page"
                              >
                              <!-- passo 5 monto a lista -->
                                  <v-data-table
                                    v-model="selected"
                                    item-key="CODIGO"

                                    fixed-header

                                    @contextmenu:row="rightClick"
                                    @click:row="seleciona_linha"

                                    :show-select="!singe_select"
                                    :single-select="singe_select"

                                  :headers="items_header"
                                  :items="items_lista"
                                  class="elevation-1"
                                  :items-per-page="500"
                                  
                                  :mobile-breakpoint="55"
                                  :hide-default-footer="(items_lista.length>500) ? false : true"
                                  
                                  :search="search"
                                  :custom-filter="SearchNoVisibles"
                                  @current-items="getFiltered($event)"

                                  dense
                                  :height="retorna_altura( (isMobile ? -200 : 20))"
                                  >
                                  <!-- :height="(isMobile) ? '' : retorna_altura(20)" -->




                                  <!-- <template v-slot:item="{ item, index }">
            
                                  <div v-for="(value, key) in item" :key="index">
                                    <span v-if="(value === 'S')">
                                      {{ value }}

                                    <span v-if="key === 'campo_desejado' && value === 'valor_desejado'">
                                      {{ item.name }} - Personalizado!
                                    </span>
                                    <span v-else>
                                      {{ key }}: {{ value }}
                                    </span>
                                  </div>
                                </template> -->


                                      <template slot="no-data">
                                      <div></div>
                                      </template>


                                      <template v-slot:item.URL_FILE="{ item }">
                                        <a> <v-img
                                            alt="user"
                                            contain
                                            :src="(item.URL_FILE)? item.URL_FILE : ''"
                                            width="50px"
                                            @click="abre_img('...', item.URL_FILE,'online' )"
                                          >
                                        </v-img></a>
                                      </template>

                                      <template v-slot:item.URL_FOTO="{ item }">
                                        <a> <v-img
                                            alt="user"
                                            :src="(item.URL_FOTO)? item.URL_FOTO : ''"
                                            width="50px"
                                            @click="abre_img('...', item.URL_FOTO,'online' )"
                                          >
                                        </v-img></a>
                                      </template>

                                      <template v-slot:item.DATA="{ item }">
                                        <span> {{ getFormatDate(item.DATA,'br') }} </span>
                                      </template>

                                      <template v-slot:item.double_VALOR="{ item }">
                                      <span class="green--text font-weight-bold">
                                       R$ {{ item.double_VALOR }}
                                      </span>
                                    </template>
                                      
                                    <template v-slot:item.SERVICO="{ item }">
                                       {{ item.SERVICO }}

                                       <span v-if="item.SERVICO=='MODULO WHATSAPP'">
                                          <v-btn x-small color="primary" class="ml-2" @click="conectar_cliente(item, 'in', 'B')">
                                            in
                                          </v-btn>
                                          
                                          <v-btn x-small color="orange" dark class="ml-2" @click="conectar_cliente(item, 'close', 'B')">
                                            close
                                          </v-btn>
                                          
                                          <v-btn x-small color="success" class="ml-2" @click="conectar_cliente(item, 'status', 'B')">
                                            status
                                          </v-btn>

                                          <v-btn x-small color="error" class="ml-2" @click="conectar_cliente(item, 'out', 'B')">
                                            out
                                          </v-btn>

                                        </span>
                                      </template>
                                      
                                    <template v-slot:item.WHATS_COMANDO="{ item }">
                                       {{ item.WHATS_COMANDO }}
                                       <v-btn x-small color="primary" class="ml-2" @click="conectar_cliente(item, 'in', 'A')">
                                        in
                                       </v-btn>

                                       <v-btn x-small color="orange" dark class="ml-2" @click="conectar_cliente(item, 'close', 'A')">
                                            close
                                          </v-btn>
                                       
                                          
                                          <v-btn x-small color="success" class="ml-2" @click="conectar_cliente(item, 'status', 'A')">
                                            status
                                          </v-btn>

                                          <v-btn x-small color="error" class="ml-2" @click="conectar_cliente(item, 'out', 'A')">
                                           out
                                          </v-btn>
                                          
                                    </template>
                                      
                                    <template v-slot:item.x_COMPROVANTE="{ item }">
                                       <!-- {{ stringToObj(item.CONTEUDO, 'comprovante') }} -->
                                        <!-- <a :href="stringToObj(item.CONTEUDO, 'comprovante')" target="_blank"> {{ stringToObj(item.CONTEUDO, 'comprovante')}} </a> -->
                                       <v-btn x-small color="primary" class="ml-2" @click="abrir_url(item.CONTEUDO)">
                                        url
                                       </v-btn>
                                    </template>

                                      <template v-slot:item.X_REAJUSTE="{ item }">
                                       R$ {{ (item.REAJUSTE > 0 )?  (parseFloat(strtofloat(item.double_VALOR)) + parseFloat(item.REAJUSTE))  : item.double_VALOR }}
                                    </template>


                                    <template v-slot:item.VERSAO="{ item }">
                                        <span class="blue--text"> 
                                          <strong> {{ item.VERSAO }} </strong> 
                                        </span>
                                    </template>

                                    <template v-slot:item.NOME="{ item }">
                                        <span class="grey--text" v-if="item.ATIVO=='N'">  
                                          <strong> {{ item.NOME }} </strong>
                                        </span>
                                        <span class="black--text" v-else> 
                                          <strong> {{ item.NOME }} </strong> 
                                        </span>
                                    </template>

                                    <template v-slot:item.MES_GEROU="{ item }">
                                        <span :class="[(item.MES_GEROU == 'S') ? '' : 'red white--text font-weight-bold', 'px-1']">  
                                           {{ item.MES_GEROU }}
                                        </span>
                                    </template>

                                    <template v-slot:item.MES_PAGOU="{ item }">
                                        <span :class="[(item.MES_PAGOU == 'S') ? 'green white--text font-weight-bold' : '', 'px-1']">  
                                           {{ item.MES_PAGOU }}
                                        </span>
                                    </template>

                          <!-- <template
                            v-slot:item="{ props: { item } }"
                          >
                                    
                                    
                          </template> -->


                                    <template v-slot:item.NOME_CLIENTE="{ item }">
                                        <span>  
                                          <strong> {{ item.NOME_CLIENTE }} </strong>
                                        </span>
                                    </template>

                                    <template v-slot:item.PAGO="{ item }">
                                        <span :class="[(item.PAGO == 'S') ? 'green white--text' : 'red white--text', 'px-1 font-weight-bold']">  
                                           {{ item.PAGO }}
                                        </span>
                                    </template>

                                    <template v-slot:item.double_TOTAL="{ item }">
                                        <span class="font-weight-bold">  
                                           {{ item.double_TOTAL }}
                                        </span>
                                    </template>

                                    <template v-slot:item.SERVICO_REFERENTE="{ item }">
                                        <span class="font-weight-bold">  
                                           {{ item.SERVICO_REFERENTE }}
                                        </span>
                                    </template>

                                    <template v-slot:item.BLOQUEAR="{ item }">
                                        <span class="red--text" v-if="item.BLOQUEAR=='S'">  
                                          <strong> {{ item.BLOQUEAR }} </strong>
                                        </span>
                                        <span class="blue--text" v-else> 
                                          <strong> {{ item.BLOQUEAR }} </strong> 
                                        </span>
                                    </template>

                                    <template v-slot:item.SITUACAO="{ item }">
                                        <span class="red--text" v-if="item.SITUACAO !='ATIVO'">  
                                          <strong> {{ item.SITUACAO }} </strong>
                                        </span>
                                        <span class="green--text" v-else> 
                                          <strong> {{ item.SITUACAO }} </strong> 
                                        </span>
                                    </template>


  
                                      <template v-slot:item.actions="{ item, index }">
                                          <v-icon
                                              class="mr-2"
                                              @click="editar_tabela(item, 'item_edita', 'dialog_tabela')"
                                              color="blue"
                                              :size = "size_mobile"
                                              v-if="pode_alterar(tabela_selecionada)"
                                          >
                                          mdi-pencil
                                          </v-icon>

                                          <span style=" text-align: right; font-size: 8px;" class="grey--text pa-0 mt-2 ml-n1"> {{ index + 1 }}º </span>  
                                      </template>




                                      <!-- MODELO DE IMPRESSAO -->
                                      <template v-slot:item.edita_modelo="{ item }">
                                        
                                        <v-tooltip 
                                            bottom
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                class="mr-2"
                                                @click="editar_impressao(item, tabela_selecionada, 'MODELO_IMPRESSAO', 'DESCRICAO')"
                                                :color="(item.MODELO_IMPRESSAO) ? 'blue': 'blue lighten-3'"
                                                :size = "size_mobile"
                                                v-bind="attrs"
                                                v-on="on"
                                                v-if="pode_alterar(tabela_selecionada)"
                                            > 
                                            mdi-file-document-multiple-outline
                                            </v-icon>

                                            </template>
                                            <span> Editar Modelo de Impressão </span>
                                        </v-tooltip>
                                          <a 
                                          @click="editar_impressao(item, tabela_selecionada, 'MODELO_IMPRESSAO', 'DESCRICAO')"
                                          v-if="pode_alterar(tabela_selecionada)"
                                          >
                                          {{(item.MODELO_IMPRESSAO ? 'Alterar':'Definir')}} 
                                          </a>
                                        </template>


                                      <!-- CONTRATO -->
                                      <template v-slot:item.edita_contrato="{ item }">

                                        
                                        <v-tooltip 
                                            bottom
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                class="mr-2"
                                                @click="editar_impressao(item, tabela_selecionada, 'CONTRATO', 'DESCRICAO')"
                                                :color="item.CONTRATO ? 'blue': 'blue lighten-3'"
                                                :size = "size_mobile"
                                                v-bind="attrs"
                                                v-on="on"
                                                v-if="pode_alterar(tabela_selecionada)"
                                            > 
                                            mdi-text-box 
                                            </v-icon>

                                            </template>
                                            <span> Editar o Contrato deste Serviço </span>
                                        </v-tooltip>
                                          <a 
                                          @click="editar_impressao(item, tabela_selecionada, 'CONTRATO', 'DESCRICAO')"
                                          v-if="pode_alterar(tabela_selecionada)"
                                          >
                                          {{(item.CONTRATO ? 'Alterar':'Definir')}} 
                                          </a>
                                        
                                        </template>
  


                                      <!-- //passo 6 personalizo as acoes dos botoes na lista  INICIO-->
                                      <template v-slot:item.actions_assinaturas="{ item }">
                                        <v-tooltip 
                                              bottom
                                          >
                                              <template v-slot:activator="{ on, attrs }">
                                              <v-icon
                                                  class="mr-2"
                                                  :size = "size_mobile"
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  color="blue"
                                                  @click="tabela('assinaturas','SUB',item), card_titulo='Assinaturas', search_2 = search, search = ''"
                                              > 
                                              <!-- :color="item.CONTRATO ? 'blue': 'grey'" -->
                                              <!-- mdi-playlist-check  -->
                                              mdi-format-list-bulleted 
                                              </v-icon>
  
                                              </template>
                                              <span> Assinaturas</span>
                                          </v-tooltip>
                                      </template>
                                      
                                      <template v-slot:item.actions_usuarios="{ item }">
                                        <v-tooltip 
                                              bottom
                                          >
                                              <template v-slot:activator="{ on, attrs }">
                                              <v-icon
                                                  class="mr-2"
                                                  :size = "size_mobile"
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  color="blue"
                                                  @click="tabela('usuarios','SUB',item), card_titulo='Usuários', search_2 = search, search = ''"
                                              > 
                                              mdi-account-lock
                                              </v-icon>
  
                                              </template>
                                              <span> Usuários</span>
                                          </v-tooltip>
                                      </template>
                                      
                                      <template v-slot:item.actions_chatbot="{ item }">
                                        <v-tooltip 
                                              bottom
                                          >
                                              <template v-slot:activator="{ on, attrs }">
                                              <v-icon
                                                  class="mr-2"
                                                  :size = "size_mobile"
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  color="blue"
                                                  @click="open_chat_edit(item)"
                                              > 
                                              mdi-refresh-auto
                                              </v-icon>
  
                                              </template>
                                              <span> ChatBot</span>
                                          </v-tooltip>
                                      </template>
                                      

                                      <template v-slot:item.actions_parcelas="{ item }">
                                        <v-tooltip 
                                              bottom
                                          >
                                              <template v-slot:activator="{ on, attrs }">
                                              <v-icon
                                                  class="mr-2"
                                                  :size = "size_mobile"
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  color="green"
                                                  @click="tabela('conta','SUB',item), card_titulo='Pagamentos', search_2 = search, search = ''"
                                              > 
                                              mdi-currency-usd 
                                              </v-icon>
  
                                              </template>
                                              <span> Pagamentos</span>
                                          </v-tooltip>
                                      </template>

                                      <template v-slot:item.actions_url_alunos="{ item }">
                                        

                                        <span v-if="(item.TIPO_CLIENTE=='CFC WEB')">
                                          <v-tooltip bottom>
                                              <template v-slot:activator="{ on, attrs }">
                                              <v-icon
                                                  class="mr-2"
                                                  :size = "size_mobile"
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  color="primary"
                                                  @click="gerar_link('aluno',item.CODIGO)"
                                              > 
                                              <!-- mdi-account-arrow-up -->
                                              mdi-account-reactivate
                                              <!-- mdi-account-star -->
                                              </v-icon>
  
                                              </template>
                                              <span> Link para Cadastrar Aluno Online </span>
                                          </v-tooltip>
                                        </span>

                                          {{ item.NOME }}
                                      </template>
  

                                      
                                      <template v-slot:item.actions_proced="{ item }">
                                        <v-tooltip 
                                              bottom
                                          >
                                              <template v-slot:activator="{ on, attrs }">
                                              <v-icon
                                                  class="mr-2"
                                                  :size = "size_mobile"
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  color="blue"
                                                  @click="tabela('VALORES_AMB','SUB',item), card_titulo='Procedimentos'"
                                              > 
                                              mdi-format-list-checkbox 
                                              </v-icon>
  
                                              </template>
                                              <span> Procedimentos</span>
                                          </v-tooltip>
                                      </template>


                                      <!-- //passo 6 personalizo as acoes dos botoes na lista  FIM -->
  
                                  </v-data-table>
                              </v-card>
                          </v-col>
                      </v-row>


                      <!-- row do rodape -->
                      <v-row dense v-if="mostrar_rodape" class="" ref="rodape_soma" id="rodape_soma">

                        <!-- aqui coloco opcao de mostrar ou nao as somatorias -->
                        <v-card height="20" class="my-3 mx-2 " elevation="0">
                          <v-row dense>
                            <v-col>
                              <v-icon  
                                  color="blue"
                                  class=""
                                  @click="ocultar_somatorias"
                                >

                                {{(!mostrar_somatorias) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                                  
                                </v-icon> 
                            </v-col>
                          </v-row>
                        </v-card>



                        <!-- mostro os cards de somatorias -->
                        <v-col 
                          class=""
                          cols="12"
                          md="11"
                          v-if="mostrar_somatorias"
                        >

                        <v-container
                        class="px-0"
                      >
                        <v-row>
                          <v-col
                            class="pa-0 pt-2"
                          >
                          
                          <v-chip-group 
                            active-class="deep-purple accent-4 white--text"
                            column
                            class="pl-0"
                            >

                            <v-chip 
                              v-for="(item,  index ) in items_somatorias"
                               dark label
                               large
                              :class="[(item.CAMPO == 'TOTAL') ? 'blue' : 'grey']"  
                              @click="search=item.CAMPO.toLowerCase()"
                            >
                            
                            <span class="mt-n3 fs-12">{{ item.CAMPO }}</span>
                            <span class="mt-n6 ml-1  white--text text-size-4 count_item_2 fs-14"> {{ item.QT }} </span>

                            <h5 class="mt-5 ml-n8" v-if="(item.VALOR!='NaN')">
                              {{ getFormatCurrency(item.VALOR,'N') }}
                            </h5>
                            <!-- <div style="position: absolute; top: 90%; left: 50%; transform: translate(-50%, -50%);">Texto Sobreposto</div> -->
                            
                            </v-chip>

                            <v-chip v-if="(obj_reajuste)" 
                              color="orange" large dark label
                            >
                              <span class="mt-n3 fs-12"> Reajuste</span>
                              <span class="mt-n6 ml-1  white--text text-size-4 count_item_2 fs-14"> {{ obj_reajuste.MAIS }} </span>

                              <h5 class="mt-5 ml-n8">
                                {{ obj_reajuste.TOTAL  }}
                              </h5>
                            </v-chip>

                          </v-chip-group>
                          </v-col>
                        </v-row>
                      </v-container>

                          <v-row class="" v-if="0>1">  
                            <v-col cols="auto" class="px-2" v-for="(item, i) in items_somatorias" :key="i">
                              <v-card elevation="4"
                                 :class="[(item.CAMPO == 'TOTAL') ? 'blue' : 'grey']" 
                              >
                                  <v-card-text class="pa-2 px-4">
                                    <span class="white--text fs-12"> {{ item.CAMPO }} </span>
                                    <span class="white--text text-size-4 count_item_2 fs-14"> {{ item.QT }} </span>                
                                    <div class="" v-if="(item.VALOR!='NaN')">
                                      <span class="white--text text-subtitle-2 "> R$ {{ getFormatCurrency(item.VALOR,'N') }} </span>
                                    </div>
                                  </v-card-text>
                              </v-card>
                            </v-col>

                            <v-col cols="auto" v-if="(obj_reajuste)">
                              <v-card class="orange" elevation="4">
                                <v-card-text class="pa-2 px-4">
                                    <span class="white--text fs-12"> Reajuste </span>
                                    <span class="white--text text-size-4 count_item_2 fs-14"> {{ obj_reajuste.MAIS }}</span>                
                                    <div class="">
                                      <span class="white--text text-subtitle-2 ">  R$ {{ obj_reajuste.TOTAL }}</span>
                                    </div>
                                  </v-card-text>
                              </v-card>
                            </v-col> 
                          </v-row> 
                        </v-col>

                      </v-row>
  
                  </div>
                  </v-card-text>
              </v-card>

              <v-card class="mx-auto grey lighten-3" v-else>
                  <v-card-title v-if="card_titulo" class="py-0">
                      <span class="text-h5"> {{ card_titulo }} </span>
                  </v-card-title>

              </v-card>
            </v-col>     
         </v-row> 
      </v-container>  
  

    <!-- MOSTRA JANELA MODAL -->
    <div class="mt-n10">
      <v-row>
        <v-col>
        <Editar_modal 
          :aberto = 'janela_modal_abre'
          :pro_tabela = 'janela_modal_tabela'
          :pro_titulo = 'janela_modal_titulo'
          :pro_obj_menus = null
          :show_menu = 'false'
          :pro_modo_edicao = 'janela_modo_edicao'
          @muda_aberto_lembretes="janela_modal_change"
        />
        </v-col>
      </v-row>
    </div>



    </div>
  </template>
  
  <script>
  
  import axios from "axios";
  var url =  sessionStorage['url']
//   import DSirotinas from '../assets/js/DSi_rotinas'//chamo funcao do arquivo
  import DSibasico from '../assets/js/DSi_basico'//chamo funcao do arquivo
  import { removerCampos, downloadText, processCsvFile, processTxtFile, get_data_hora } from '../assets/js/DSi_basico'
  import DSisql from '../assets/js/DSi_sql'//chamo funcao do arquivo
  import DSicanal from '../assets/js/DSi_canal'//chamo funcao do arquivo
  import { logado } from '../assets/js/DSi_rotinas'
  
  
  
  export default {

      components:{
        Editar_modal: () => import("./Editar.vue")
      },

      data: () => ({



          janela_modal_abre  : false,
          janela_modal_tabela: '',
          janela_modal_titulo: '',
          janela_modo_edicao : false,
        
          items_tabela_categoria:[],
          items_tabela_categoria_operacao:[],

          cliente_selecionado : {},
          souDSi : false,
          can_show : false,

          dialog_img_mais:'',
          dialog_img_titulo:'',
          dialog_img_url:'',
          dialog_img: false,

          obj_reajuste : null,

          menu_de_tabelas: [],
          menu_de_tabelas_selecionados: [],

          items_somatorias:[],

          mostrar_somatorias_altura: 0,
          mostrar_somatorias: false,

          mostrar_rodape: false,

          obj_user:{},

          overlay: false,

          tipo_situacao: 'ATIVO',
          tipo_cliente: 'TODOS',

          items_importado:[],

          dialog_modal: false,
          modal_titulo:'',
          modal_items : [],
          modal_edita: {},
          modal_item_clicou : null,
          modal_width : '999px',


          items_meu_sql: [],

          items_tabela_contas: [],

          dialog_menu: false,
          item_menu:{},

          qual_janela: '',


          items_filtrados: [],
          selected: [],
          selecionou_varios: false,
          singe_select: true,

          superUser: false,
          superA : false,
          eu_sou : '',

          session_bd:'',
          size_bd:'',
          

          col_menu: 2,

          w_actions: '60px',
          w_data: '99px',
          w_fone: '125px',
          w_cpf: '120px',
          w_cnpj: '150px',
          w_nome: '200px',
          w_situacao: '60px',
          w_valor: '100px',


          files_upload: [],
          urlLogo:'',

          profissionais:['a','b'],

          selectedItem: -1,

          isMobile: false, 
  
          card_titulo:'',
          referencia:'',
  
          dialog_tabela: false,
          tabela_selecionada:'',
  
          search: '',
          search_2: '',
  
          aguardar_refresh: false,
  
          size_mobile:22,
          
          lista_tabelas:[],

          sub_codigo:0,
          sub_descricao:'',
          voltar_para:'',
  
          items_lista:[],
          items_header: [],
          items_editaveis: [],
          item_edita: {},
  
         snackbar: false,
         snack_text: 'My timeout is set to 2000.',
         snack_color: "success",
  
         notnullRules: [
          v => !!v || 'necessário'
         ],

         noRules:[v => true],
  
         valido: false,
  
      }),
  


      watch: {


        janela_modal_abre(a){
          // quando fecho a janela pode chamar uma funcao
          if (!a){
            this.acao_after_modal(this.janela_modal_titulo)//passo o nome da tabela
          }
        },

        mostrar_somatorias(a){
          this.size_panel()
        },


        selected(val){
          this.selecionou_varios = (this.selected?.length > 1)
        },

        'item_edita.SERVICO'(val){
          // alert(val)
           this.campos_adicionais(this.item_edita)
        },

      },

      created(){
        this.onCreated()
      },
  

      computed: {
        filteredItems() {
          return this.$refs.dataTable.filteredItems;
        },
      },

      methods:{

        async entao_remover_cadastro(){

          if (this.item_menu.CODIGO_ALUNO<1){
             return false
          }


          if (!confirm("Remover Associação de Cadastro deste Lançamento?")){
            return false
          }

          let sql = `update tabela_fixas set CODIGO_ALUNO = 0, CODIGO_SERVICO = 0 where codigo = ${this.item_menu.CODIGO}`
          await this.crud_sql(sql)
          await this.tabela('tabela_fixas')
        },

        abre_janela_modal(status, edicao, tabela, titulo){
        
          this.janela_modal_tabela   = tabela
          this.janela_modal_titulo   = titulo
          this.janela_modo_edicao    = edicao
          this.janela_modal_abre     = status
        },

        janela_modal_change(valor){
          this.janela_modal_abre = valor
        },

        async acao_after_modal(acao){
          if (acao == 'CADASTRO'){
            
            let ccadastro
            
            try{
              ccadastro = JSON.parse(sessionStorage['cadastro_selecionados'])
              ccadastro = ccadastro[0]
            }catch(e){
              return false
            }


            if (!ccadastro){
              return false
            }

            console.log('ccadastro:', ccadastro);

            let sql = `update tabela_fixas set CODIGO_ALUNO = ${ccadastro.CODIGO_ALUNO}, CODIGO_SERVICO = ${ccadastro.CODIGO_SERVICO} where codigo = ${this.item_menu.CODIGO}`
            await this.crud_sql(sql)

            await this.tabela('tabela_fixas')
            // console.log('sql:', sql);
            // this.$set(this.edita_parcelas, 'CODIGO_ALUNO', ccadastro.CODIGO_ALUNO)
            // this.$set(this.edita_parcelas, 'CODIGO_SERVICO', ccadastro.CODIGO_SERVICO)
            
          }
        },

        async monta_items_combobox(){
        

          let sql = ''

          sql = `select distinct CATEGORIA from tabela_categorias order by CATEGORIA`
          // sql = `select distinct CATEGORIA from conta where (CATEGORIA <>' ') and(VER_CATEGORIA = 'S') and (tipo = 'S') order by CATEGORIA`
          await this.crud_abrir_tabela(sql, 'items_tabela_categoria' )
          
          
          sql = `SELECT DISTINCT CONCAT(tabela_categorias.CATEGORIA, ' - ', tabela_categorias.OPERACAO)  AS CATEGORIA
                  FROM tabela_categorias
                  ORDER by 
                  tabela_categorias.CATEGORIA`
          await this.crud_abrir_tabela(sql, 'items_tabela_categoria_operacao' )
         
          
        },

        gerar_link(tipo, ide){

          // alert(ide)
          
          
          try {
            ide  = ide * 777888
          }catch (error) { return false}


          let link = `https://dsi.app.br/f/c/#/12052009/${ide}`

          if (url =='/api/'){
              link = `#/12052009/${ide}` //localhost
          }


          console.log('link:', link);
          if (tipo == 'aluno'){
              window.open(link, '_blank');         
          }
        },

        async handleFileUpload(event) {

          let file = event.target.files[0];
          this.dados_importados = [];

          if (file) {
            const fileName = file.name;
            const fileExtension = fileName.split('.').pop().toLowerCase();

            if (fileExtension === 'csv') {

                this.dados_importados = await processCsvFile(file);

            } else if (fileExtension === 'txt') {
              
                this.dados_importados = await processTxtFile(file);

            } else {
              console.error('Tipo de arquivo não suportado');
            }

            event.target.value = null; // limpa valor do input
          }
        },

        async exportArray(name, get_array) {
          
          //converte array to string
          const texto_salvar = JSON.stringify(get_array)
          console.log('get_array:', get_array);

          downloadText(texto_salvar, name+'.txt');//DSi_basico.js

        },

        abrir_url(entra){

          let uurl = ''
          if (entra){

            uurl = this.stringToObj(entra, 'comprovante')

            window.open(uurl, '_blank');
          }
        },

        stringToObj(entra, campo){

          let valor = ``

          if (entra){
          
            let oobj = JSON.parse(entra);
            console.log('oobj:', oobj);

            if (campo == 'comprovante'){
                valor = oobj.payment.transactionReceiptUrl;
                return valor
                // console.log('valor:', valor);
            }

          }
          else{
            return entra
          }
        },

        open_chat_edit(item){
          console.log('open_chat_edit:', item);

          let route = this.$router.resolve({path: '/WhatsBot'});
          sessionStorage['obj_cliente_selecionado']  =  JSON.stringify(item)
          window.open(route.href, '_blank');
        },

        conectar_cliente(item, acao, origem){

           let ss 
           if (origem == 'A'){//comandos
             ss =  item.WHATS_COMANDO
           }
           else if (origem == 'B'){//assinaturas em clientes
             ss =  item.API_LINK
             ss = ss.replaceAll('@token', this.cliente_selecionado.TOKEN) 
           }



           if (acao == 'in'){
             ss = ss.replaceAll('/send-messages', '') 
           }
           else if (acao == 'close'){

              if (confirm("Deseja Fechar Navegador este Cliente?")){
                ss = ss.replaceAll('/send-messages', '/close') 
              }else{
                return false
              }
           }
           else if (acao == 'out'){

              if (confirm("Deseja Desconectar este Cliente?")){
                ss = ss.replaceAll('/send-messages', '/logout') 
              }else{
                return false
              }
           }
           else if (acao == 'status'){
            ss = ss.replaceAll('/send-messages', '/status') 
           }

           window.open(ss, '_blank');
        },

        abre_img(titulo, url_img, mais){
           this.dialog_img        = true
           this.dialog_img_titulo = titulo
           this.dialog_img_url    = url_img
           this.dialog_img_mais   = mais
        },

        async reajustar_valores(acao){
          
          await this.define_data_hora()

           this.obj_reajuste = {}//limpa 

           let sum = 0
           let dif = 0

           let h = this.items_filtrados
           let dias = 0
           let valor = 0
           let reajuste = 0

           for (let i = 0; i < h?.length; i++) {
            
            dias     = await DSibasico.diferenca_datas( h[i].DATA, this.today)
            valor    = this.strtofloat(h[i].double_VALOR)
            reajuste = parseFloat(h[i].REAJUSTE)      

            if ((dias > 270)){//se a data do serviço for mais que 9 meses entao pode reajustar em janeiro
              //se reajusta entao somar  reajsue +  valor atual
             dif = dif + ((reajuste) ? reajuste: 0)
             reajuste = ((reajuste) ? reajuste: 0) + parseFloat(valor)
            //  console.log('reajuste:', reajuste);

            }
            else{
              reajuste = parseFloat(valor)
              // console.log('REAJUSTAR NA PROXIMA VEZ: ', h[i].X_NOME, ' : ', dias,' dias');
              // console.log('valor:', valor);
            }

            sum  = parseFloat(sum) + parseFloat(reajuste)

           }

           this.$set(this.obj_reajuste, 'TOTAL', this.floatTostring(sum))
           this.$set(this.obj_reajuste, 'MAIS', this.floatTostring(dif))


           if (acao == 'reajustar_simular'){
               console.log('items_filtrados:', this.items_filtrados);
           }
        },

        size_panel(){
          setTimeout(function(){
               this.mostrar_somatorias_altura = this.get_altura_comp('rodape_soma')
           }.bind(this), 0);
        },

        get_altura_comp(id){

          const componente = document.getElementById(id)
            // console.log('get_altura_comp:', componente);

            let altura = 0
            if (componente){
              altura = componente.offsetHeight
            }

            return altura

        },

        getFiltered(e){

          this.items_filtrados = e //pega os items filtrados
          
            //  console.log(this.items_filtrados) //output the filtered items

          let item_name  = (e) ? 'items_filtrados' : 'items_lista'

          if (['clientes'].includes(this.tabela_selecionada)){
            this.somatoria_lista( item_name , 'double_VALOR', 'TOTAL')
          }

          else if (['assinaturas'].includes(this.tabela_selecionada)){
             this.somatoria_lista( item_name, 'double_VALOR', 'X_TIPO_CLIENTE')
          }

          else if (['tabela_api_whats'].includes(this.tabela_selecionada)){
            //  this.somatoria_lista( item_name, 'QT', 'NOME_EMPRESA')
            //  alert('here')
          }

        },


        async executarSQL(sql){
          if (confirm("Deseja continuar?")){
             await this.crud_sql(sql)
          }
        },


        async onCreated(){

          if (await logado(this)){
          
            await this.showDrawer(true)

            //busco objeto no sessionStorage
            this.obj_user = JSON.parse(sessionStorage['obj_user'])

            this.qual_janela = this.$route.name
            this.souDSi = (sessionStorage['souDSi'] == 'S') ? true : false 
            this.superA = (sessionStorage['lg:USUARIO'] == 'A') ? true : false 
            this.permite_items()
            
            
            this.urlLogo =  sessionStorage['emp_url_logo']
            this.session_bd =  'cfc_'+(sessionStorage['lg:IDE']) 
            this.size_bd    =  sessionStorage['lg:SIZE'] / 777


            this.ultimo_click()
            this.abre_tabelas_necessarias()

            this.mostrar_somatorias = (localStorage['F:mostrar_somatorias'] == 'true')           

            console.log('um_ano:', get_data_hora('um_ano'));

          }
        },


        ocultar_somatorias(){
          this.mostrar_somatorias =! this.mostrar_somatorias
          localStorage['F:mostrar_somatorias'] = this.mostrar_somatorias
        },

        async somatoria_lista(item_name, campo_valor,  campo_parametro , evitar_string){

            // percorro o array de objeto somando os valores pelo nome do campo..
            const resultado = {};
            this.items_somatorias = []; // Limpa o array de somatórias

            if (campo_parametro) {
                let total = 0;

                this[item_name].forEach((item) => {
                    const campo = item[campo_parametro] || campo_parametro; // Se o campo não existir, usa o valor de campo_parametro
                    const valor = parseFloat(this.strtofloat(item[campo_valor]));

                    if (resultado[campo]) {
                        resultado[campo] += parseFloat(valor);
                    } else {
                        resultado[campo] = parseFloat(valor);
                    }

                    total += valor;
                });

                resultado['TOTAL'] = parseFloat(total);

                // Criar um novo array de objetos com os valores agrupados e a quantidade por campo
                const novoArrayDeObjetos = Object.keys(resultado).map((campo) => {
                    return {
                        CAMPO: campo,
                        VALOR: resultado[campo].toFixed(2),
                        'QT': campo !== 'TOTAL' ? this[item_name].filter(item => item[campo_parametro] === campo).length : this[item_name].length
                        // 'QT': campo !== 'TOTAL' ? this[item_name].filter(item => item[campo_parametro] === campo).length : ''
                    };
                });

                // console.log(novoArrayDeObjetos);
                if (evitar_string){//faço um filtro tirando chaves que contenham alguma string que nao quero mostrar
                  this.items_somatorias = novoArrayDeObjetos.filter(elemento => !elemento.CAMPO.includes(evitar_string));
                }else{
                  this.items_somatorias = novoArrayDeObjetos;
                }
                
            }   
        },

        monta_menu_de_tabelas(qual){

          // alert(qual)

          this.menu_de_tabelas              = []//limpa
          this.menu_de_tabelas_selecionados = []//limpa

          if (this.pode_alterar(this.tabela_selecionada)){

              this.menu_de_tabelas.push(   
                {type:'menu', title: 'Alterar',   icon:'mdi-pencil',    icon_color: 'primary',    click:'alterar' },
              )
          }

          if (qual == 'tabela_fixas'){

             this.menu_de_tabelas.push(    
              {type:'menu', title: 'Gerar Despesa ...',       icon:'mdi-currency-usd',    icon_color: 'primary',    click:'gerar_despesas' },
              {type:'menu', title: 'Associar Cadastro',       icon:'mdi-account'     ,    icon_color: 'primary',    click:'associar_cadastro' },
              {type:'menu', title: 'Remover Cadastro',        icon:'mdi-account-minus',   icon_color: 'red',        click:'remover_cadastro' },
             )

             this.menu_de_tabelas_selecionados.push(    
              {type:'menu', title: 'Gerar Despesas ...',       icon:'mdi-currency-usd',    icon_color: 'primary',    click:'gerar_despesas' },
             )
          }
          
          else if (qual == 'clientes'){

             this.menu_de_tabelas.push(    
              {type:'menu', title: 'Gerar Parcela ...',       icon:'mdi-currency-usd',    icon_color: 'primary',    click:'clientes_gerar_parcela' },
              {type:'menu', title: 'Gerar Parcela (deste mês)', icon:'mdi-currency-usd',    icon_color: 'primary',    click:'clientes_gerar_parcela_mes' },
             )

             this.menu_de_tabelas_selecionados.push(    
              {type:'menu', title: 'Gerar Parcelas ...',       icon:'mdi-currency-usd',    icon_color: 'primary',    click:'clientes_gerar_parcela_selec' },
              {type:'menu', title: 'Atualizar Versão ...',     icon:'mdi-arrow-up-box',    icon_color: 'primary',    click:'clientes_atu_versao_selec' },
              {type:'menu', title: 'Exportar Contatos',        icon:'mdi-card-account-phone-outline',    icon_color: 'primary',    click:'clientes_exp_contato' },
             )
          }

          else if (qual == 'tabela_webhooks'){
             this.menu_de_tabelas.push(    
              {type:'menu', title: 'Excluir Pendentes',       icon:'mdi-delete-circle-outline',    icon_color: 'red',    click:'tw_excluir_todos' },
              {type:'menu', title: 'Excluir Todos'    ,       icon:'mdi-delete-circle-outline',    icon_color: 'red',    click:'tw_excluir_all' },
             )
          }

          else if (qual == 'tabela_api_whats'){
             this.menu_de_tabelas.push(    
              {type:'menu', title: 'Excluir Pendentes',       icon:'mdi-delete-circle-outline',    icon_color: 'red',    click:'taw_excluir_todos_pendentes' },
              {type:'menu', title: 'Excluir Todos'    ,       icon:'mdi-delete-circle-outline',    icon_color: 'red',    click:'taw_excluir_todos_all' },
             )
          }

          else if (qual == 'assinaturas'){
             this.menu_de_tabelas.push(    
              {type:'menu', title: 'Simular Reajuste',       icon:'mdi-currency-usd',    icon_color: 'orange',    click:'reajustar_simular' },
             )
          }

        },

        clicou_menu_de_tabelas(acao){
          // alert(acao)
          this.dialog_menu = false

          if (acao == 'reajustar_simular'){
             this.reajustar_valores(acao)
          }
          
          if (acao == 'remover_cadastro'){
             this.entao_remover_cadastro()
          }
          
          if (acao == 'associar_cadastro'){
            this.abre_janela_modal(true, false, 'ALUNOS', 'CADASTRO')
          }
          
          if (acao == 'gerar_despesas'){
            this.abre_modal( this.selected)
          }
          
          if (acao == 'alterar'){
             this.editar_tabela(this.item_menu, 'item_edita', 'dialog_tabela')
          }
          
          if (acao == 'clientes_gerar_parcela'){
             this.abre_modal(this.item_menu)
          }
          
          if (acao == 'clientes_gerar_parcela_selec'){
             this.abre_modal( this.selected)
          }
          
          if (acao == 'clientes_atu_versao_selec'){
             this.abre_modal_versao( this.selected)
          }
          
          if (acao == 'clientes_exp_contato'){

            // const contatos = this.selected.map(objeto => ({
            //       NOME    : objeto.NOME,
            //       CELULAR : objeto.CELULAR,

            //       CELULAR_2_NOME : objeto.CELULAR_2_NOME,
            //       CELULAR_2      : objeto.CELULAR_2,

            //       CELULAR_3_NOME : objeto.CELULAR_3_NOME,
            //       CELULAR_3      : objeto.CELULAR_3
            // }));
            const contatos = [];

            this.selected.forEach(objeto => {
              if (objeto.CELULAR) {
                contatos.push({ NOME: objeto.NOME, CELULAR: objeto.CELULAR });
              }
              if (objeto.CELULAR_2) {
                contatos.push({ NOME: objeto.CELULAR_2_NOME, CELULAR: objeto.CELULAR_2 });
              }
              if (objeto.CELULAR_3) {
                contatos.push({ NOME: objeto.CELULAR_3_NOME, CELULAR: objeto.CELULAR_3 });
              }
            });

            // console.log('contatos:', contatos);
            this.exportArray('contatos_clientes', contatos)

          }
          
          if (acao == 'clientes_gerar_parcela_mes'){
             this.gerar_parcela_link(this.item_menu)
          }
                    
          if (acao == 'tw_excluir_todos'){
             if (this.executarSQL(`delete from tabela_webhooks where visto = 'N'`)){
                this.tabela(this.tabela_selecionada,'') 
             }
          }
                    
          if (acao == 'tw_excluir_all'){
             if (this.executarSQL(`delete from tabela_webhooks`)){
                this.tabela(this.tabela_selecionada,'') 
             }
          }
          
          if (acao == 'taw_excluir_todos_pendentes'){
             if (this.executarSQL(`delete from tabela_api_whats where visto = 'N'`)){
                console.log(`delete from tabela_api_whats where visto = 'N'`);
                this.tabela(this.tabela_selecionada,'') 
             }
          }
          
          if (acao == 'taw_excluir_todos_all'){
             if (this.executarSQL(`delete from tabela_api_whats`)){
                this.tabela(this.tabela_selecionada,'') 
             }
          }

        },


        async campos_adicionais(item){

          // return 'exit'


          if (this.tabela_selecionada=='assinaturas'){
          
              removerCampos(this, 'items_editaveis', ['CELULAR_TESTE', 'CELULAR_RESPONDER', 'USAR_LINK_ATENDENDE', 'API_LINK','SALVAR_WEBHOOK'] )
              
              if (['MODULO WHATSAPP'].includes(item.SERVICO)){

                  this.items_editaveis.push(
                    // { text: 'WhatsApp para Testes', value: 'CELULAR_TESTE',tag:'v-text', type:'text', cols:'4',rule:'N',vmask:'cel', width: this.w_fone, hint:'Quando em TESTE, as mensagens serão enviadas para este número' },
                    { text: 'Salvar WebHook', value: 'SALVAR_WEBHOOK',type:'text',tag:'v-select',tag_items:['S','N','A','C','B','V'],cols:'2', rule:'S', width: this.w_situacao, hint:'Sim, Não, Agenda, Campanha, Bot, VirtualSec' } ,
                    // { text: 'ABRIR IMPRESSÃO',  tag:'v-chip', cols:'2', color:'primary', icon:'mdi-information', click:'get_impressao'},
                    // { text: 'ABRIR IMPRESSÃO',  tag:'v-icon', cols:'1', color:'primary', icon:'mdi-information', click:''},
                    { text: 'API da instância', value: 'API_LINK',
                      type:'text',
                      tag:'v-autocomplete',
                      tag_items:[
                          'http://dsi.app.br:8051/@token/send-messages',         
                          'http://localhost:8051/@token/send-messages',                  
                      ],
                      cols:'6',rule:'N' , upper:'N'
                    },
                    { text: 'ID DOC ou USER', value: 'ID_USER',tag:'v-text', type:'number', cols:'2',rule:'N', width: this.w_fone, hint:'Digite o codigo do doutor ou do usuario ou 0 para todos' },

                  )
              }
              
          }

           if (this.tabela_selecionada=='assinaturasOLD'){

               if (item.SERVICO != 'MODULO WHATSAPP'){

                  //removo a coluna senha   
                  let remover = ['CELULAR_TESTE', 'CELULAR_RESPONDER', 'USAR_LINK_ATENDENDE', 'API_LINK','SALVAR_WEBHOOK']
                  let index   = 0

                  for (let i = 0; i < remover?.length; i++) {
                      index =  this.items_editaveis.findIndex(x => x.value === remover[i])
                      if (index > 0){
                        this.items_editaveis.splice( index , 1);
                      }
                      // alert(index + ' = '+ remover[i])
                  }

               }
               else{

                this.items_editaveis.push(

                    { text: 'WhatsApp para Testes', value: 'CELULAR_TESTE',tag:'v-text', type:'text', cols:'4',rule:'N',vmask:'cel', width: this.w_fone, hint:'Quando em TESTE, as mensagens serão enviadas para este número' },
                    { text: 'Salvar WebHook', value: 'SALVAR_WEBHOOK',type:'text',tag:'v-select',tag_items:['S','N','A','C','F'],cols:'2', rule:'S', width: this.w_situacao, hint:'A = somente respostas de confirmação de consulta'} ,
                    { text: 'API da instância', value: 'API_LINK',
                      type:'text',
                      tag:'v-autocomplete',
                      tag_items:[
                          'http://dsi.app.br:8051/@token/send-messages',         
                          'http://localhost:8051/@token/send-messages',                  
                      ],
                      cols:'10',rule:'N' , upper:'N'
                    },
                )
               }
               
           }
        },

        getFormatCurrency(v,string){
          if (v){
            return DSibasico.getFormatCurrency(v, string)
          }
          else{
            return v
          }
          
        },

        async sum_clientes(tipo){

          if (tipo == 'TODOS'){ tipo = `' or 1> '0`}

          let sql = 
          `SELECT 
            'Assinaturas' as CAMPO,
            SUM(assinaturas.double_VALOR) AS VALOR,
            COUNT(assinaturas.CODIGO) AS QT
          FROM 
            assinaturas
            INNER JOIN clientes ON (assinaturas.CODIGO_CLIENTE=clientes.CODIGO)
           WHERE
            (assinaturas.double_VALOR >0)and
            (clientes.SITUACAO = '${tipo}')`

            // console.log('sql:',sql);
          await this.crud_abrir_tabela(sql,'items_somatorias')  

            
        },

        async importar_db(){
          let sql = ''

          // ALUNOS.CODIGO,
          sql = `
          SELECT
            ALUNOS.CODIGOALUNO AS CODIGO_ALUNO,
            ALUNOS.ATIVO,
            ALUNOS.CONCLUIUTUDO AS STATUS,
            ALUNOS.NOMECOMPLETO AS NOME,
            EXTRACT(YEAR FROM ALUNOS.DATA_CADASTROU)||'-'||EXTRACT(MONTH FROM ALUNOS.DATA_CADASTROU)||'-'||EXTRACT(DAY FROM ALUNOS.DATA_CADASTROU) AS DATA_CADASTRADO,
            ALUNOS.PROTOCOLO AS PROCESSO,
            EXTRACT(YEAR FROM ALUNOS.DATADOCADASTRO)||'-'||EXTRACT(MONTH FROM ALUNOS.DATADOCADASTRO)||'-'||EXTRACT(DAY FROM ALUNOS.DATADOCADASTRO) AS DATA_PROCESSO,
            ALUNOS.CATEGORIA,
            ALUNOS.RGALUNO AS RG,
            ALUNOS.CPFALUNO AS CPF,
            ALUNOS.CELULARALUNO AS CELULAR,
            ALUNOS.CELULARALUNO_2 AS CELULAR_2,
            ALUNOS.FONE2ALUNO AS FONE,
            ALUNOS.EMAIL,
            ALUNOS.ALUNOWORK AS PROFISSAO,
            ALUNOS.CEP,
            (ALUNOS.ENDERECO||' '||ALUNOS.ENDNUMERO) AS ENDERECO,
            ALUNOS.COMPLEMENTO,
            ALUNOS.BAIRRO,
            ALUNOS.CIDADE,
            ALUNOS.UF,
            ALUNOS.NOMEPAI AS NOME_PAI,
            ALUNOS.NOMEMAE AS NOME_MAE,
            EXTRACT(YEAR FROM ALUNOS.DATANASCIMENTO)||'-'||EXTRACT(MONTH FROM ALUNOS.DATANASCIMENTO)||'-'||EXTRACT(DAY FROM ALUNOS.DATANASCIMENTO) AS DATA_NASCIMENTO,
            ALUNOS.OBS AS OBSERVACAO,
            ALUNOS.NATURALIDADE,
            ALUNOS.NACIONALIDADE,
            ALUNOS.RGORGAO AS RG_ORGAO,
            ALUNOS.SEXO,
            ALUNOS.ESTADOCIVIL AS ESTADO_CIVIL,
            ALUNOS.LADV,
            ALUNOS.KEMALTEROU AS QUEM_ALTEROU,
            ALUNOS.PERIODO_AULA
          FROM
          ALUNOS
          `
          await this.crud_abrir_tabela_fdb(sql, 'items_importado')
          // await this.salvar_tabela('alunos', this.items_importado[0])


          await percorre_salvando(this, this.items_importado)

            async function percorre_salvando(that, lista){

              that.dialog_tabela = false
              that.overlay = true

              let sql_multi = ''

              for (let i = 0; i < lista?.length; i++) {
                await that.tabela_create('alunos', lista[i])
              }    
              

              that.overlay = false
              
            }

          

        },


        //ABRE OS DADOS DA TABELA
        async  crud_abrir_tabela_fdb(sql, items_name){

          if (items_name){
              this[items_name] = []//limpa antes
          }

          var meuSQL = sql
          // console.log(meuSQL, db);
                  
          const res = await axios.post(url+'conexao_fdb.php', {
              acao:   5,
              meuSQL: meuSQL
          })

          if (items_name){
              this[items_name] = await res.data 
          }

          console.log('crud_abrir_tabela_2:', res.data);
          return await res.data
        },


        SearchNoVisibles (value, search, item) {
           return Object.values(item).some(v=>v&&v.toString().toLowerCase().includes(search))
        },

        async update_dados_pagamentos(){

          // FAÇO UM UPDATE DOS DADOS. SEM TER QUE FICAR BUSCANDO QUERY..

          let r = await DSibasico.dias_do_mes()

          let sql = 
           `
            update clientes set clientes.MES_GEROU = 'N';
            update clientes set clientes.MES_PAGOU = 'N';

            UPDATE clientes set clientes.MES_GEROU = 'S' WHERE
            clientes.CODIGO IN
            (SELECT 
            conta.CODIGO_CLIENTE
            FROM 
            conta
            WHERE  
            (DATA_VENCIMENTO BETWEEN '${r.de}' and '${r.ate}') 
            );


            UPDATE clientes set clientes.MES_PAGOU = 'S' WHERE
            clientes.CODIGO IN
            (SELECT 
            conta.CODIGO_CLIENTE
            FROM 
            conta
            WHERE  
            (PAGO = 'S')and
            (DATA_VENCIMENTO BETWEEN '${r.de}' and '${r.ate}') 
            );

            UPDATE clientes set clientes.double_VALOR = 
            (
            select sum(assinaturas.double_VALOR) as TOTAL
            FROM assinaturas WHERE assinaturas.CODIGO_CLIENTE = clientes.CODIGO
            );
           `
          // console.log(sql);

          await this.crud_sql(sql)

          await this.ultimo_click()//atualiza tabela
        },

        async showDrawer(value){
          this.$emit('executar', value)//mostrar ou nao paineis
          this.can_show = true
          this.isMobile = window.innerWidth < 600
        },

        
        async abre_modal_versao( item){

           this.modal_item_clicou = item
           this.modal_titulo = 'Número da Versao'
           this.modal_width  = '400px'
           this.dialog_modal = true
           this.dialog_menu  = false

            //monta campos do dialog modal (pegar data de quando gerar parcela)
            this.modal_items = [
              { text: 'Versão'   , value: 'VERSAO', tag:'v-text', type:'number', cols:'6'},
              { text: ''      , value: '', type:'number', cols:'2' }, //em branco                   
              { text: 'Atualizar' , value: '', tag:'v-btn', cols:'2', color:'primary', click:'before_gerar_versao'},
            ]

        },
        
        async abre_modal( item){

           await this.define_data_hora()


           this.modal_item_clicou = item
           this.modal_titulo = 'Selecione o Mês para Gerar'
           this.modal_width  = '400px'
           this.dialog_modal = true
           this.dialog_menu  = false

            //monta campos do dialog modal (pegar data de quando gerar parcela)
            this.modal_items = [
              { text: 'Mês'   , value: 'DATA', tag:'v-text', type:'date', cols:'6'},
              { text: ''      , value: '', type:'number', cols:'3' }, //em branco                   
              { text: 'Gerar' , value: '', tag:'v-btn', cols:'2', color:'primary', click:'before_gerar_parcela'},
            ]

            //define alguns valores default
            this.modal_edita.DATA = this.today
        },

        modal_clicou(click){

           if (this.tabela_selecionada == 'tabela_fixas'){
             this.dialog_modal = false
             this.before_gerar_despesas((this.selecionou_varios) ? this.selected : this.item_menu)
           }
           
           else if (click == 'before_gerar_parcela'){
              this.dialog_modal = false
              this.before_gerar_parcela(this.modal_item_clicou)
           }
           
           else if (click == 'before_gerar_versao'){
              this.dialog_modal = false
              this.before_gerar_versao(this.modal_item_clicou)
           }
           
           else if (click == 'importar_db'){
              this.importar_db()
           }
        },
        

        async before_gerar_parcela(item){

          this.overlay = true

          if (item.constructor === Array){

            // console.log('é array');
            for (let i = 0; i < item?.length; i++) {
              await this.gerar_parcela(item[i])
            }
          }
          else{
            // console.log('NAO é array');
            await this.gerar_parcela(item)
          }


          await atualiza(this)
            async function atualiza(that){
              setTimeout(function(){
                that.ultimo_click()
                that.alerta('Concluído','blue')
                that.overlay = false
              }.bind(that), 555);
            }

        },

        async before_gerar_versao(item){

          this.overlay = true

          let sql = ''
          for (let i = 0; i < item?.length; i++) {
            sql = `update clientes set versao = ${this.modal_edita.VERSAO} where codigo = ${item[i].CODIGO}`
            await this.crud_sql(sql)
          }

          await atualiza(this)
            async function atualiza(that){
              setTimeout(function(){
                that.ultimo_click()
                that.alerta('Concluído','blue')
                that.overlay = false
              }.bind(that), 555);
            }

        },


        async gerar_parcela(item){
          
          let sql = `select * from assinaturas where codigo_cliente = ${item.CODIGO}`
          
          await this.crud_abrir_tabela(sql,'items_meu_sql')
          
          await percorre(this, item)

            async function percorre(that, dados_cliente){

              let item       = that.items_meu_sql
              let total      = 0
              let valor      = 0 
              let ref        = ''
              let data_vence = that.modal_edita.DATA
              data_vence     = data_vence.substring(0, 8) + dados_cliente.DIA_VENCIMENTO


              // PERCORRE LISTA DE ASSINATURAS
              for (let i = 0; i < item?.length; i++) {
                  valor = that.strtofloat(item[i].double_VALOR)
                  total += parseFloat(valor);
                  ref   += `${item[i].SERVICO} ${item[i].double_VALOR},⠀`
              }

            

              let obj_edita = {
                DATA_VENDA       : that.today,
                DATA_VENCIMENTO  : data_vence,
                DATA_EFETUADO    : null,
                PAGO             : 'N',
                CONTA_REFERENTE  : 'DSI',
                CODIGO_CONTA     : '1',
                VISTO            : 'N',
                TIPO             : 'E',
                QUEM_ALTEROU     : that.data_hora,
                double_VALOR     : that.floatTostring(total),
                double_TOTAL     : that.floatTostring(total),
                FORMA_PAGAMENTO  : dados_cliente.FORMA_PAGAMENTO,
                SERVICO_REFERENTE: ref   ,
                OBSERVACAO       : dados_cliente.NOME,
                CODIGO_CLIENTE   : dados_cliente.CODIGO,
                MULTA            : '0',
                JUROS            : '0'
              }

              await that.salvar_tabela('conta', obj_edita)
            }

        },


        async before_gerar_despesas(item){

          this.overlay = true

          if (item.constructor === Array){

            // console.log('é array');
            for (let i = 0; i < item?.length; i++) {
              await this.gerar_despesas(item[i])
            }
          }
          else{
            // console.log('NAO é array');
            await this.gerar_despesas(item)
          }


          await atualiza(this)
            async function atualiza(that){
              setTimeout(function(){
                that.ultimo_click()
                that.alerta('Concluído','blue')
                that.overlay = false
              }.bind(that), 555);
            }

        },

        async gerar_despesas(item){
              console.log('gerar_despesas:', item);
              

              let valor = this.strtofloat(item.double_VALOR)
              let total = parseFloat(valor);
                  

              let data_vence = this.modal_edita.DATA
              data_vence     = data_vence.substring(0, 8) + item.DIA
            

              let obj_edita = {
                DATA_VENDA       : this.today,
                DATA_VENCIMENTO  : data_vence,
                DATA_EFETUADO    : null,
                PAGO             : 'N',
                CONTA_REFERENTE  : 'DSI',
                CODIGO_CONTA     : item.CODIGO_CONTA,
                
                CODIGO_ALUNO     : item.CODIGO_ALUNO,
                CODIGO_SERVICO   : item.CODIGO_SERVICO,

                VISTO            : 'N',
                TIPO             : 'S',
                VER_CATEGORIA    : 'S',
                QUEM_ALTEROU     : this.data_hora,
                double_VALOR     : this.floatTostring(total),
                double_TOTAL     : this.floatTostring(total),
                FORMA_PAGAMENTO  : item.FORMA_PAGAMENTO,
                CATEGORIA        : item.CATEGORIA  ,
                SERVICO_REFERENTE: item.REFERENTE  ,
                OBSERVACAO       : '',
                CODIGO_CLIENTE   : 0,
                MULTA            : '0',
                JUROS            : '0'
              }

              await this.salvar_tabela('conta', obj_edita)

        },

        async gerar_parcela_link(item){
          console.log(item);

          let link = ''
          let teste = sessionStorage['url'];
          if (teste == '/api/'){
            link = `http://localhost/vue_cfc/public/clientes/?token=ci45i${item.CODIGO}`//teste
          }
          else{
            link = `https://dsi.app.br/f/c/clientes/?token=ci45i${item.CODIGO}`//produção
          }
                

          const res = await axios.get(link);
          console.log(res.data);
          this.alerta(res.data.msg,'green')

        },

        rightClick(event, item) {
        // console.log(event)
        // console.log(item)

        this.abre_menu(item.item)
        event.preventDefault()
       },

       abre_menu(item){
        
        this.dialog_menu = true
        this.item_menu = item

        this.seleciona_linha(item)
      },

        retorna_altura(menos){
          // let r = '100vh'  
          // let r = '720'
          // console.log('retorna_altura');
          let rodape = 0
          // let somatorias = 44
          // let somatorias = this.get_altura_comp('rodape_soma')
          let somatorias = this.mostrar_somatorias_altura
        
          // let somatorias = 0
          if (this.mostrar_rodape){
            //  rodape = 65 
             rodape = 20 
            //  if (this.mostrar_somatorias){ somatorias = 31 }
             if (this.mostrar_somatorias){ 
              // somatorias = this.get_altura_comp('rodape_soma')
              somatorias = this.mostrar_somatorias_altura
             }
          }
          



          let r = (window.innerHeight - 50 - 60 - rodape - somatorias - menos)
          let height = window.innerHeight;

          return r
        },


        seleciona_linha(row) {
          // console.log('row:');
          // console.log(row);
          //  this.selected= [{"CODIGO" : row.CODIGO}] //via v-modal no data-table

          if (this.selected?.length <= 1){

              this.selected  = [ Object.assign({}, row)] 
 
              //clonando objeto
              this.obj_clicado = Object.assign({}, row)

              // this.item_menu = row

              
              if (this.tabela_selecionada == 'clientes'){
                this.cliente_selecionado  = Object.assign({}, row)
              }
          }


        },

        async consulta_cep(cep){

          if (cep?.length>=9){

            let link = "https://viacep.com.br/ws/"+cep+"/json/"
            const res = await axios.get(link);
            // console.log(res.data);

            this.$set(this.item_edita, 'ENDERECO', res.data.logradouro.toUpperCase())
            this.$set(this.item_edita, 'BAIRRO',   res.data.bairro.toUpperCase())
            this.$set(this.item_edita, 'CIDADE',   res.data.localidade.toUpperCase())
            this.$set(this.item_edita, 'UF',       res.data.uf.toUpperCase())

          }
        } , 

        async upload_logo(file,pasta,nome,campo_salvar){

          // alert(campo_salvar)
          // console.log(file);


        //update refresh image
         this.urlLogo = URL.createObjectURL(file);

         const data = {
                    
                    pasta:          pasta,
                    nome_arquivo:   nome, 
                    userfile:       file,
          }  

          
          //isso daqui server para ser lido com o $_POST no php 
          const header =   { headers: {'Content-Type': 'multipart/form-data'}}

          const res = await axios.post(url+'upFile.php', data, header)

          console.log(res.data);

          let r = ''

          // console.log(res.data);

          if (res.data.status == 'OK'){
           
              this.alerta('Atualizado','green')
            
              let tipo = file.type
              tipo = tipo.substr(tipo.indexOf('/')+1,5);
            
              r = ( pasta+'/'+nome+'.'+tipo)

              sessionStorage['emp_url_logo'] =  sessionStorage['url'] + 'images/'+ r //salvo para ser lido
              

          }else{
              this.alerta('Ops: '+res.data,'red')
          }

          //salvo no campo editavel no caso dados da empresa
          this.item_edita[campo_salvar] = r

        },


        async ultimo_click(){

          let tabela = sessionStorage['tabela_clicou']
          let janela = sessionStorage['tabela_janela']

          // alert(tabela+' - '+janela)
          if ((tabela) && (janela == this.qual_janela)){
              this.card_titulo = sessionStorage['tabela_titulo'] 
              // let voltar_para = this.sub_descricao
              
              await this.tabela(tabela,'')

              this.search = this.voltar_para.toLowerCase()
          }
        },

        getVmask(qual,valor){
            
            
            if (qual == 'cel'){
                return "(##) #####-####"
            }
            else if (qual == 'fone'){
                return "(##) ####-####"
            }
            else if (qual == 'cnpj'){
                return "##.###.###/####-##"
            }
            else if (qual == 'cpf'){
                return "###.###.###-##"
            }
            else if (qual == 'cep'){
                return "#####-###"
            }
            else{
                return null
            }
        },

        async abre_tabelas_necessarias(){
            
            let sql = `select codigo,nome from doutores where ativo = 'S' order by doutores.nome`
            await this.crud_abrir_tabela(sql, 'profissionais')
            
            
            sql = `select codigo,nome from doutores where ativo = 'S' order by doutores.nome`
            await this.crud_abrir_tabela(sql, 'profissionais')

            sql = `select * from tabela_contas where ativo = 'S'`
            await this.crud_abrir_tabela(sql, 'items_tabela_contas' )
        },

        update_server(){
            let url = sessionStorage.getItem('url')
            // alert(url)
            url =  url+'upCoptyToRaiz.php?togo='+url
            // alert(url)
            
            // let link = 'https://www.google.com/maps/search/'+item
            let link = url
            window.open(link, '_blank');

            //saio para atualizar
            this.sair()
        },

        sair(){

          sessionStorage.setItem('logout','S')//manda fazer logout no / = login

          sessionStorage.setItem('reload_page','S')
          this.$router.push('/')//redireciona para a pagina login

          //quando redireciono pro / ele executa o limpar_session()//zerando dados de login
        } ,

        permite_items(){
            //passo 1 monto os items e suas tabelas e permissoes

            let s = []


            if (this.qual_janela == 'dsi'){


                //  permissoes
                if (this.souDSi){
                  this.lista_tabelas.push({ text: 'Clientes',                   color:'blue',  icon: 'mdi-account-credit-card',      tabela:'clientes' })
                  this.lista_tabelas.push({ text: 'Assinaturas',                color:'blue',  icon: 'mdi-format-list-bulleted',     tabela:'assinaturas', referencia:'' })
                  this.lista_tabelas.push({ text: 'Users (geral)',              color:'blue',  icon: 'mdi-account-lock',             tabela:'usuarios',  referencia:'geral' })
                  this.lista_tabelas.push({ text: 'Formas de Cobrança',         color:'blue',  icon: 'mdi-credit-card-multiple',     tabela:'tabela_cobranca' })
                  this.lista_tabelas.push({ text: 'API W - Comandos'            , color:'blue',  icon: 'mdi-whatsapp',                 tabela:'tabela_api_whats' })
                  // this.lista_tabelas.push({ text: 'Whats Enviados'            , color:'blue',  icon: 'mdi-list-box-outline',                 tabela:'whats_enviados' })
                  this.lista_tabelas.push({ text: 'API W - WebHooks'            , color:'blue',  icon: 'mdi-webhook',                  tabela:'tabela_webhooks' })
                  this.lista_tabelas.push({ text: 'API W - Não Enviar'          , color:'blue',  icon: 'mdi-account-off',              tabela:'tabela_nao_whats' })

                  this.lista_tabelas.push({ text: 'API W - Interação'          , color:'orange',  icon: 'mdi-account-voice',              tabela:'whats_interacao' })
                  this.lista_tabelas.push({ text: 'API W - Menus'              , color:'green',  icon: 'mdi-touch-text-outline',              tabela:'whats_menus' })
                  this.lista_tabelas.push({ text: 'API W - Respostas'          , color:'green',  icon: 'mdi-touch-text-outline',              tabela:'whats_respostas' })
                  
                  this.lista_tabelas.push({ text: 'PIX - WebHooks'          , color:'black',  icon: 'mdi-barcode',              tabela:'pix_webhooks' })
                  // this.lista_tabelas.push({ text: 'API W - ChatBot'            , color:'blue',  icon: 'mdi-refresh-auto',              tabela:'WhatsBot' })
                  // this.lista_tabelas.push({ text: 'Vendas',             color:'blue',  icon: 'mdi-cart-arrow-right',         tabela:'vendas' })
                }

            }

            if (this.qual_janela == 'tabelas'){


                //  permissoes
                if (this.souDSi){
                  // this.lista_tabelas.push({ text: 'Vendas',             color:'blue',  icon: 'mdi-cart-arrow-right',         tabela:'vendas' })
                }


                this.lista_tabelas.push({ text: 'Empresa',               icon: 'mdi-home-analytics',                   tabela:'empresa' })
                this.lista_tabelas.push({ text: 'Usuários',              icon: 'mdi-account-lock',    color:'',     tabela:'usuarios',  referencia:'dsi45' })
                this.lista_tabelas.push({ text: 'Contas',                icon: 'mdi-home-plus',                        tabela:'tabela_contas' })
                this.lista_tabelas.push({ text: 'Formas de Pagamento',   icon: 'mdi-credit-card-check',                tabela:'tabela_formas' })

                if (this.obj_user.SUPER == 'S'){
                    this.lista_tabelas.push({ text: 'Despesas Fixas',        icon: 'mdi-credit-card-clock',  color:'',  tabela:'tabela_fixas' })
                }

                this.lista_tabelas.push({ text: 'Serviços',              icon: 'mdi-format-list-bulleted-square',      tabela:'tabela_servicos' })
                this.lista_tabelas.push({ text: 'Impressão',             icon: 'mdi-file-document-multiple-outline',   tabela:'tabela_impressao', novo:'N' })
                this.lista_tabelas.push({ text: 'Categoria - Operação',  icon: 'mdi-chart-bar',                 tabela:'tabela_categorias', color:'blue' })
                
                this.lista_tabelas.push({ text: 'Veículos',              icon: 'mdi-car',                              tabela:'veiculos' })
                this.lista_tabelas.push({ text: 'Instrutores',           icon: 'mdi-account-voice',                    tabela:'instrutores' })

                
                if (this.obj_user.SUPER == 'S'){
                    this.lista_tabelas.push({ text: 'Formas de Cobrança',         color:'blue',  icon: 'mdi-credit-card-multiple',     tabela:'tabela_cobranca' })
                }

            }
            else if (this.qual_janela == 'configuracoes'){
              
                this.lista_tabelas = [
                    // { text: 'Financeiras', icon: 'mdi-chart-bar', tabela:'empresa' },
                    { text: 'Gerais', icon: 'mdi-cogs', tabela:'empresa' },
                ]

                if (this.souDSi){
                  this.lista_tabelas.push(
                    { text: 'Banco de Dados', icon: 'mdi-database-cog ', tabela:'BD', referencia:'Painel' },
                  )
                }
            }


        },

        define_defaults(tabela){

            // --------------------DEFINE DATA E HORA-----------------------
            let hoje = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });  
            // console.log('hoje:'+ hoje);

            let today = hoje.slice(6, 10) +'-'+ hoje.slice(3, 5) +'-'+ hoje.slice(0, 2) ; //formato us yyyy-mm-dd
            // console.log('today:'+ today);

            let hora = new Date().toLocaleTimeString('pt-Br',{ hour12: false, timeZone: 'America/Sao_Paulo' });
            let hora_curta = hora.slice(0, 5)
            // console.log('hora = '+ hora);
            // console.log('hora = '+ hora_curta);
            // --------------------DEFINE DATA E HORA-----------------------


            // passo 7 defino valores default para alguns campos de determinada tabela
           if (tabela=='vendas'){
               this.$set(this.item_edita, 'DATA', today)
               this.$set(this.item_edita, 'EFETUADO', 'N')
               this.$set(this.item_edita, 'COUNT_UPDATE', 0)
               this.$set(this.item_edita, 'TIPO_CLIENTE', 'CLINICA')
           }
           else if (tabela=='clientes'){
               this.$set(this.item_edita, 'DATA_CADASTRADO', today)
               this.$set(this.item_edita, 'SITUACAO', 'ATIVO')
               this.$set(this.item_edita, 'BLOQUEAR', 'N')
               this.$set(this.item_edita, 'DIA_VENCIMENTO', '10')
           }
           else if (tabela=='conta'){
               this.$set(this.item_edita, 'DATA_VENDA'      , today)
               this.$set(this.item_edita, 'DATA_VENCIMENTO' , today)
               this.$set(this.item_edita, 'PAGO'            , 'N')
               this.$set(this.item_edita, 'VISTO'           , 'N')
               this.$set(this.item_edita, 'TIPO'            , 'E')
               this.$set(this.item_edita, 'FORMA_PAGAMENTO' , 'BOLETO')
               this.$set(this.item_edita, 'CODIGO_CONTA'    , 1)
               this.$set(this.item_edita, 'CONTA_REFERENTE' , 'DSI')
               this.$set(this.item_edita, 'CODIGO_CLIENTE'  , this.cliente_selecionado.CODIGO)
               this.$set(this.item_edita, 'CATEGORIA'       , this.cliente_selecionado.TIPO_CLIENTE)
               this.$set(this.item_edita, 'OBSERVACAO'      , this.cliente_selecionado.NOME)
               this.$set(this.item_edita, 'QUEM_ALTEROU'    , DSibasico.get_quem_alterou())
           }
           else if (tabela=='assinaturas'){
               this.$set(this.item_edita, 'DATA'                , today)
               this.$set(this.item_edita, 'SITUACAO'            , 'ATIVO')
               this.$set(this.item_edita, 'SALVAR_WEBHOOK'      , 'N')
               this.$set(this.item_edita, 'REAJUSTE'            , '10')
               this.$set(this.item_edita, 'CODIGO_CLIENTE'      , this.sub_codigo)
               this.$set(this.item_edita, 'ID_USER'             , 0)

               this.$set(this.item_edita, 'double_VALOR_ANTIGO' , 0)
               this.$set(this.item_edita, 'ANO_REAJUSTOU'       , get_data_hora('ano_atual'))
               this.$set(this.item_edita, 'DATA_REAJUSTAR'      , get_data_hora('um_ano'))
           }
           else if (tabela=='usuarios'){

               this.$set(this.item_edita, 'DATA', today)
               this.$set(this.item_edita, 'ATIVO', 'S')

               if (this.referencia=='dsi45'){
                this.$set(this.item_edita, 'CODIGO_CLIENTE', this.size_bd)
                this.$set(this.item_edita, 'NOME_CLIENTE', sessionStorage['lg:EMPRESA'])
               }
               else{
                this.$set(this.item_edita, 'CODIGO_CLIENTE', this.sub_codigo)
                this.$set(this.item_edita, 'NOME_CLIENTE', this.sub_descricao)
               }

               this.$set(this.item_edita, 'SUPER', 'N')
               this.$set(this.item_edita, 'CADASTRO_U', 'S')
               this.$set(this.item_edita, 'FINANCEIRO_R', 'S')
               this.$set(this.item_edita, 'FINANCEIRO_U', 'N')
               this.$set(this.item_edita, 'TABELAS_R', 'S')
               this.$set(this.item_edita, 'TABELAS_U', 'N')
               
               this.$set(this.item_edita, 'PARC_GERAR', 'S')
               this.$set(this.item_edita, 'PARC_ALTERA', 'N')
               this.$set(this.item_edita, 'PARC_ESTORNA', 'N')
               this.$set(this.item_edita, 'PARC_EXCLUI', 'N')
               
           }
           
        },

        async before_update(tabela){
          // passo 8 antes de salvar verifico alguns campos necessarios em determinada tabela

          // VERIFICA CAMPOS NUMERICOS QUE NAO PODEM IR VAZIOS
          let VERIFICAR = [
            'LANCAMENTOS|DATA|null',
          ]
        
          let CAMPOS      = []
          let CAMPO_VER   = ''
          let CAMPO_VALOR = ''
          for (let i = 0; i < VERIFICAR.length; i++) {
             
             CAMPOS      = VERIFICAR[i].split("|");//divide
             CAMPO_VER   = CAMPOS[1]
             CAMPO_VALOR = this.item_edita[CAMPO_VER]

             if (tabela==CAMPOS[0]){//verifica se é a tabela selecionada
                if (!CAMPO_VALOR){//verifica se vazio
                  this.$set(this.item_edita, CAMPOS[1], CAMPOS[2] ) 
                }
             }
          }

          if (tabela == 'conta'){
             if (this.item_edita.double_TOTAL != this.item_edita.double_VALOR){
                 this.$set(this.item_edita, 'double_TOTAL', this.item_edita.double_VALOR)
             }
             if (this.item_edita.PAGO == 'S'){
                 this.$set(this.item_edita, 'QUEM_EFETUOU', DSibasico.get_quem_alterou())
             }
          }


          // verifica alguns outros campos
          if ((tabela=='usuarios') && (this.referencia == 'dsi45')){
              
              if ((this.item_edita.SENHA != this.item_edita.X_SENHA) && (this.item_edita.X_SENHA)){
                alert('Senhas não conferem!')
                return false
              }
          }

          return true
        },

        pode_novo(tabela){
            
            if ( 
                (tabela == 'tabela_formas') || (tabela == 'tabela_servicos') || (tabela == 'tabela_fixas') ||
                (tabela == 'tabela_impressao') || (tabela == 'veiculos') || (tabela == 'instrutores') ||(tabela == 'tabela_cobranca') 
               ){
                return  ( (this.obj_user.SUPER=='S') || (this.obj_user.TABELAS_U=='S') )
            }
            else if  (tabela == 'tabela_contas') {
                return  ((this.obj_user.SUPER=='S'))
            }
            else if (tabela == 'empresa'){
              return false
            }
            else if (((tabela == 'usuarios') && (this.referencia == 'geral'))){
              return true
              
            }
            else if ((tabela == 'usuarios') && (this.referencia == 'dsi45')){

              return  (this.obj_user.SUPER=='S')
            }
            else{
                return true
            }

            return esse
            
        },

        pode_alterar(tabela){
            
            if ( 
                (tabela == 'empresa') || (tabela == 'tabela_contas') || (tabela == 'tabela_formas') || (tabela == 'tabela_servicos') 
                || (tabela == 'tabela_impressao') || (tabela == 'veiculos') || (tabela == 'instrutores') ||(tabela == 'tabela_fixas') 
                ||(tabela == 'tabela_cobranca') 
               ){
              return  ( (this.obj_user.SUPER=='S') || (this.obj_user.TABELAS_U=='S') )
            }
              //  ((tabela == 'usuarios') && (this.referencia == 'geral'))){
              // return false
            // }
            else{
                return true
            }

            return esse
            
        },


          //---------------importando funcoes basicas ----------------------- INICIO
          
          alerta(text,color){
              DSibasico.alerta(this, text, color)
          },
  
          moeda(event){
              return DSibasico.moeda(event)
          },

          logado(){
            // DSirotinas.logado(this)
          },

          getFormatDate(data){
            let dia =   data.substring(8,10) + '/'+ data.substring(5,7)+ '/'+ data.substring(0,4);//formato a dd/mm/yyyy
            return dia 
          },
  
          //---------------importando funcoes basicas ----------------------- FIM
  
  
  
  
           //---------------importando funcoes sql ----------------------- INICIO
          //EXECUTAR UM SQL TIPO UPDATE
          async crud_sql (sql) {
            if (this.referencia =='dsi45'){
              await DSicanal.crud_sql_45(this, sql)
            }
            else{
              await DSisql.crud_sql(this, sql)
            }
          },
  
          async tabela_create(tabela, obj_edita){
            if (this.referencia =='dsi45'){
              DSicanal.tabela_create_45(this, tabela, obj_edita)
            }
            else{
              DSisql.tabela_create(this, tabela, obj_edita)
            }
          },
  
          async tabela_update(tabela, obj_edita){
            if (this.referencia =='dsi45'){
              DSicanal.tabela_update_45(this, tabela, obj_edita)
            }
            else{
              DSisql.tabela_update(this, tabela, obj_edita)
            }
              
          },
  
          //ABRE OS DADOS DA TABELA
          async crud_abrir_tabela(sql, items_name){
            if (this.referencia =='dsi45'){
              await DSicanal.crud_abrir_tabela_45(this, sql, items_name)
            }
            else{
              await DSisql.crud_abrir_tabela(this, sql, items_name)
            }
             
          }, 
  
          //FICA NO BOTAO SALVAR.. VERIFICA SE UPDATE OU CREATE
         async salvar_tabela(tabela, obj_edita, dialog_name){

             //  await this.before_update(this.tabela_selecionada)//trato alguns campos necessarios
             //trato alguns campos necessarios
             if(await this.before_update(this.tabela_selecionada) == false) {
              return 'exit'
             }

             if (this.referencia =='dsi45'){
                await DSicanal.salvar_tabela_45(this,tabela, obj_edita, dialog_name)
             }
             else{
                await DSisql.salvar_tabela(this,tabela, obj_edita, dialog_name)
             }
          },
  
          async remover_tabela(tabela, obj_edita, titulo){
            if (this.referencia =='dsi45'){
              await DSicanal.remover_tabela_45(this, tabela, obj_edita, titulo)
            }
            else{
              await DSisql.remover_tabela(this, tabela, obj_edita, titulo)
            }
          },
  
          async editar_tabela(item, obj_edita_name, dialog_name) {

            this.campos_adicionais(this.item_edita)

            if (this.referencia =='dsi45'){
              await DSicanal.editar_tabela_45(this, item, obj_edita_name, dialog_name)
            }
            else{
              await DSisql.editar_tabela(this, item, obj_edita_name, dialog_name)
            }
              
          },
  
          nova_tabela(obj_edita_name,dialog_name){

             DSisql.nova_tabela(this, obj_edita_name,dialog_name)

             //define alguns valores default
             this.define_defaults(this.tabela_selecionada)
          },
  
  
           //---------------importando funcoes sql ----------------------- FIM
  
  
  
  
          refresh_page(){
              // @mouseover="refresh_page" uso isso para atualizar a tabela depois de abrir uma nova janela e fazer alterações la
  
              // console.log('refresh_page:');
              if (this.aguardar_refresh){
                  this.aguardar_refresh = false
  
                  //refresh da tabela selecionada
                  this.tabela(this.tabela_selecionada,'')
              }
          },
  
          async tabela(tabela, acao, item){
          //passo 3 executo a acao quando clicar nos items e monto os campos editaveis e os campos do data-table


             // seleciona_linha tem a mesma funcao.. faço isso pra pegar o clientes que foi clicado 
              if (this.tabela_selecionada == 'clientes'){
                this.cliente_selecionado  = Object.assign({}, item)
              }

          
              this.obj_reajuste = null
              this.mostrar_rodape = false

              if ((!item)&&(!acao)){
                sessionStorage['tabela_clicou']   = tabela
                sessionStorage['tabela_titulo']   = this.card_titulo
                sessionStorage['tabela_janela']   = this.qual_janela
              }

  
              // this.search = ''//limpa filtro

              this.tabela_selecionada = tabela
              // this.card_titulo = item.text

            //   console.log('item:');
            //   console.log(item);
  
              //limpa array
              this.items_lista       = [] //recebe os dados do sql
              this.items_header      = [] //campos que tem que mostrar no header
              this.items_editaveis   = [] //campos editaveis mas nao tem que mostrar no header


              // valores default
              this.singe_select = true

              this.monta_menu_de_tabelas(tabela)



              
              if (tabela=='tabela_fixas'){
                
                this.singe_select = false

                await this.monta_items_combobox()


                this.items_header = [

                    { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width: this.w_actions},
                    { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0',width:'10px' },
                                   
                    { text: 'Conta', value: 'CODIGO_CONTA',
                          type:'text',
                          tag:'v-select',
                          tag_items: this.items_tabela_contas,
                          tag_item_text : 'DESCRICAO',
                          tag_clear:'N',
                          cols:'2' ,
                          hint:'',
                          rule:'S'
                    },

                    { text: 'Conta', value: 'x_DESCRICAO_CONTA', tag:'v-text', type:'text', cols:'2', rule:'N', width: '150px'},
                    // { text: 'Categoria', value: 'CATEGORIA', tag:'v-text', type:'text', cols:'2', rule:'S', width: '300px'},

                    { text: 'Categoria', value: 'CATEGORIA',
                      type:'text',
                      tag:'v-autocomplete',
                      tag_items: this.items_tabela_categoria_operacao,

                      item_value:'CATEGORIA',
                      item_text : 'CATEGORIA',
                      return_object : false,

                      cols:'4',rule:'S' , upper:'S'
                    },

                    { text: 'Referente', value: 'REFERENTE', tag:'v-text', type:'text', cols:'4', rule:'S', width: '400px'},
                    
                    { text: 'Valor', value: 'double_VALOR', tag:'v-text', type:'text', cols:'2', rule:'S', width: this.w_valor },
                    { text: 'Forma', value: 'FORMA_PAGAMENTO', tag:'v-text', type:'text', cols:'2', rule:'S', width: '90px'},
                    { text: 'Dia', value: 'DIA', tag:'v-text', type:'number', cols:'1', rule:'S'},

                    // { text: 'CODIGO_ALUNO', value: 'CODIGO_ALUNO', tag:'v-text', type:'text', cols:'4', rule:'N'},
                    // { text: 'CODIGO_SERVICO', value: 'CODIGO_SERVICO', tag:'v-text', type:'text', cols:'4', rule:'N'},

                    { text: 'Cadastro', value: 'x_NOME', tag:'v-text', type:'text', cols:'4', rule:'N'},
                    { text: 'Serviço', value: 'x_SERVICO', tag:'v-text', type:'text', cols:'4', rule:'N'},
                  ]
                  
                  // clono os items
                  this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array


                  // // removo os campos que nao quero visivel na edicao
                  this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "x_DESCRICAO_CONTA") , 1);//removo a coluna senha
                  this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "x_SERVICO") , 1);//removo a coluna senha
                  this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "x_NOME") , 1);//removo a coluna senha
                  
                  // // removo os campos que nao quero visivel somente para edicao
                  this.items_header.splice(this.items_header.findIndex(x => x.value === "CODIGO_CONTA") , 1);//removo a coluna senha

                  let sql = `
                      SELECT
                        tabela_fixas.CODIGO,
                        tabela_fixas.CODIGO_CONTA,
                        tabela_fixas.CATEGORIA,
                        tabela_fixas.REFERENTE,
                        tabela_fixas.DIA,
                        tabela_fixas.double_VALOR,
                        tabela_fixas.FORMA_PAGAMENTO,
                        tabela_fixas.CODIGO_ALUNO,
                        tabela_fixas.CODIGO_SERVICO,
                        tabela_contas.DESCRICAO AS x_DESCRICAO_CONTA,
                        alunos.NOME AS x_NOME,
                        servicos.DESCRICAO AS x_SERVICO
                      FROM
                        tabela_fixas
                        LEFT OUTER JOIN tabela_contas ON tabela_fixas.CODIGO_CONTA = tabela_contas.CODIGO
                        LEFT OUTER JOIN alunos ON tabela_fixas.CODIGO_ALUNO = alunos.CODIGO_ALUNO
                        LEFT OUTER JOIN servicos ON tabela_fixas.CODIGO_SERVICO = servicos.CODIGO
                      ORDER BY 
                        tabela_fixas.CODIGO_CONTA,
                        tabela_fixas.DIA

                  ` 

                  // console.log(tabela+': '+sql);
                  await this.crud_abrir_tabela(sql, 'items_lista')

                  // console.log('items_lista:', this.items_lista);

                  // somatoria por grupo
                  await this.somatoria_lista('items_lista', 'double_VALOR', 'x_DESCRICAO_CONTA')

                  // somatorio total
                  // await this.somatoria_lista('items_lista', 'double_VALOR', 'TOTAL')

                  this.mostrar_rodape = true 

              }
              
              else if (tabela=='tabela_nao_whats'){
                
                this.items_header = [

                
                  { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width: this.w_actions},
                  // { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },              
                  { text: 'CLIENTE', value: 'CODIGO_CLIENTE', tag:'v-text', type:'text', cols:'1', rule:'N' },      
                  { text: 'Tipo', value: 'TIPO', tag:'v-text', type:'text', cols:'2', rule:'N' },      
                  { text: 'Data', value: 'DATA', tag:'v-text', type:'date', cols:'2', width: this.w_data,rule:'N' },      
                  { text: 'Empresa', value: 'NOME_EMPRESA', tag:'v-text', type:'text', cols:'2', rule:'N' },      
                  { text: 'Celular', value: 'CELULAR', tag:'v-text', type:'text', cols:'3', rule:'N' },      
                  { text: 'Motivo', value: 'MOTIVO', tag:'v-text', type:'text', cols:'2', rule:'N' },
                  ]

                  // clono os items
                  this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

                  let sql = `SELECT *  FROM `+tabela + ` order by codigo desc` 

                  console.log(tabela+': '+sql);
                  await this.crud_abrir_tabela(sql, 'items_lista')


              }
              
              else if (tabela=='tabela_api_whats'){
                
                this.items_header = [

                
                  { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width: this.w_actions},
                  // { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },              
                  { text: 'CLIENTE', value: 'CODIGO_CLIENTE', tag:'v-text', type:'text', cols:'1', rule:'N' },      
                  { text: 'Tipo', value: 'TIPO', tag:'v-text', type:'text', cols:'2', rule:'N' },      
                  { text: 'Visto', value: 'VISTO', type:'text', tag:'v-select', tag_items:['S','N','L'],cols:'2',rule:'S'  },      
                  { text: 'Resp', value: 'RESPONDEU', type:'text', tag:'v-select', tag_items:['S','N',],cols:'2',rule:'S'  },      
                  { text: 'Data', value: 'DATA', tag:'v-text', type:'date', cols:'2', width: this.w_data,rule:'N' },      
                  { text: 'Hora', value: 'HORA', tag:'v-text', type:'text', cols:'2', rule:'N' },      
                  { text: 'Hora Vistado', value: 'HORA_VISTADO', tag:'v-text', type:'text', cols:'2', rule:'N',  width:'150px' },      
                  { text: 'Empresa', value: 'NOME_EMPRESA', tag:'v-text', type:'text', cols:'2', rule:'N' },      
                  { text: 'Usuario', value: 'NOME_USUARIO', tag:'v-text', type:'text', cols:'2', rule:'N' },      
                  { text: 'Celular', value: 'CELULAR', tag:'v-text', type:'text', cols:'2', rule:'N' },      
                  { text: 'SERIALIZED', value: 'SERIALIZED', tag:'v-text', type:'text', cols:'2', rule:'N' },      

                  { text: 'Delay', value: 'DELAY', tag:'v-text', type:'number', cols:'2', rule:'N' },      
                  { text: 'URL_FILE', value: 'URL_FILE', tag:'v-text', type:'text', cols:'12', rule:'N', upper:'N' },      

                  { text: 'Mensagem', value: 'WHATS_MSG',tag:'v-textarea', type:'text', cols:'12',rule:'N', width:'333px' },
                  { text: 'Comando', value: 'WHATS_COMANDO',tag:'v-text', type:'text', cols:'12',rule:'N', upper:'N' },
                  { text: 'Whats Retorno', value: 'WHATS_RETORNO',tag:'v-textarea', type:'text', cols:'12',rule:'N'},
                  { text: 'DSi Id', value: 'WHATS_ID_DSI', tag:'v-text', type:'text', cols:'2', rule:'N' },      
                  { text: 'Whats Id', value: 'WHATS_ID', tag:'v-text', type:'text', cols:'2', rule:'N' },      
                  ]

                  // clono os items
                  this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

                  let sql = `SELECT *  FROM `+tabela + ` order by codigo desc` 

                  // console.log(tabela+': '+sql);
                  await this.crud_abrir_tabela(sql, 'items_lista')

                  this.mostrar_rodape = true
                  await this.somatoria_lista('items_lista', 'QT', 'NOME_EMPRESA', 'DSI -')

              }
              
              // else if (tabela=='WhatsBot'){
              //   let route = this.$router.resolve({path: '/WhatsBot'});
              //   sessionStorage['obj_cliente_selecionado']  =  JSON.stringify(this.obj_cliente_selecionado)
              //   window.open(route.href, '_blank');
              // }

              else if (tabela=='whats_menus'){
                
                this.items_header = [

                
                  { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width: this.w_actions},
                  { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },              
                  { text: 'CLIENTE', value: 'CODIGO_CLIENTE', tag:'v-text', type:'text', cols:'2', rule:'N' },      
                  { text: 'TOKEN', value: 'TOKEN', tag:'v-text', type:'text', cols:'3', rule:'N',upper:'N' },      
                  { text: 'COD_CAMPANHA', value: 'COD_CAMPANHA', tag:'v-text', type:'text', cols:'2', rule:'N' },
                  { text: 'INICIAL', value: 'INICIAL', tag:'v-text', type:'text', cols:'2', rule:'N' },
                  { text: 'Ativo', value: 'ATIVO', type:'text', tag:'v-select', tag_items:['S','N',],cols:'2',rule:'S'  },      
                  { text: 'Descricao', value: 'DESCRICAO', tag:'v-text', type:'text', cols:'10', rule:'S', upper:'S' },
                  { text: 'Texto da Mensagem', value: 'TEXTO',tag:'v-textarea', type:'text', cols:'12',rule:'N', width:'333px' },  

                  { text: 'URL_FILE', value: 'URL_FILE', tag:'v-text', type:'text', cols:'12', rule:'N', upper:'N' },
                  
                  { text: 'Intervalo', value: 'INTERVALO_TIPO',type:'text',tag:'v-select',tag_items:['DIAS','HORAS','MINUTOS'],cols:'2', rule:'N', hint:'Horas: Máximo de 23 Minutos: Máximo de 59'}, 
                  { text: 'De', value: 'INTERVALO', tag:'v-text', type:'number', cols:'1', rule:'N' },             

                  { text: 'DAS', value: 'HORA_DAS', tag:'v-text', type:'time', cols:'2', rule:'N' },      
                  { text: 'ATÉ', value: 'HORA_ATE', tag:'v-text', type:'time', cols:'2', rule:'N' },   

                  { text: 'Texto de Ausência', value: 'TEXTO_AUSENTE',tag:'v-textarea', type:'text', cols:'12',rule:'N', width:'333px' },  

                        
                     
                  ]

                  // clono os items
                  this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

                  let sql = `SELECT *  FROM `+tabela + ` order by CODIGO_CLIENTE, CODIGO` 

                  console.log(tabela+': '+sql);
                  await this.crud_abrir_tabela(sql, 'items_lista')

                  // this.mostrar_rodape = true
                  // await this.somatoria_lista('items_lista', 'QT', 'NOME_EMPRESA', 'DSI -')

              }
              
              else if (tabela=='whats_respostas'){
                
                this.items_header = [

                
                  { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width: this.w_actions},
                  { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },              
                  { text: 'CLIENTE', value: 'CODIGO_CLIENTE', tag:'v-text', type:'text', cols:'2', rule:'N' },      
                  { text: 'TOKEN', value: 'TOKEN', tag:'v-text', type:'text', cols:'3', rule:'N',upper:'N' },      
                  { text: 'COD_MENU', value: 'COD_MENU', tag:'v-text', type:'text', cols:'2', rule:'N' },
                  { text: 'CODIGO_FLUXO', value: 'CODIGO_FLUXO', tag:'v-text', type:'text', cols:'2', rule:'N' },
                  { text: 'Tipo', value: 'TIPO', tag:'v-text', type:'text', cols:'2', rule:'N' },             
                  { text: 'Texto da Mensagem', value: 'TEXTO',tag:'v-textarea', type:'text', cols:'12',rule:'N', width:'333px' },  

                  { text: 'Intervalo', value: 'INTERVALO_TIPO',type:'text',tag:'v-select',tag_items:['DIAS','HORAS','MINUTOS'],cols:'2', rule:'N', hint:'Horas: Máximo de 23 Minutos: Máximo de 59'}, 
                  { text: 'De', value: 'INTERVALO', tag:'v-text', type:'number', cols:'1', rule:'N' },             

                  { text: 'DAS', value: 'HORA_DAS', tag:'v-text', type:'time', cols:'2', rule:'N' },      
                  { text: 'ATÉ', value: 'HORA_ATE', tag:'v-text', type:'time', cols:'2', rule:'N' },   

                  { text: 'Texto de Ausência', value: 'TEXTO_AUSENTE',tag:'v-textarea', type:'text', cols:'12',rule:'N', width:'333px' },  
                  
                  


                  { text: 'URL_FILE', value: 'URL_FILE', tag:'v-text', type:'text', cols:'12', rule:'N', upper:'N' },      
                     
                  ]

                  // clono os items
                  this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

                  let sql = `SELECT *  FROM `+tabela + ` order by CODIGO_CLIENTE, COD_MENU, CODIGO_FLUXO  ` 

                  console.log(tabela+': '+sql);
                  await this.crud_abrir_tabela(sql, 'items_lista')

                  // this.mostrar_rodape = true
                  // await this.somatoria_lista('items_lista', 'QT', 'NOME_EMPRESA', 'DSI -')

              }
              
              else if (tabela=='whats_interacao'){
                
                this.items_header = [

                
                  { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width: this.w_actions},
                  // { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },              
                  { text: 'CLIENTE', value: 'CODIGO_CLIENTE', tag:'v-text', type:'text', cols:'2', rule:'N' },      
                  { text: 'TOKEN', value: 'TOKEN', tag:'v-text', type:'text', cols:'3', rule:'N',upper:'N' },      
                  { text: 'VISTO', value: 'VISTO', tag:'v-text', type:'text', cols:'1', rule:'N',upper:'N' },      
                  { text: 'ID_MENU', value: 'ID_MENU', tag:'v-text', type:'text', cols:'2', rule:'N' },
                  { text: 'Tipo', value: 'TIPO',type:'text',tag:'v-select',tag_items:['A','B'],cols:'1', rule:'N'},     
                  { text: 'Data', value: 'DATA', tag:'v-text', type:'date', cols:'2', width: this.w_data,rule:'N' },      
                  { text: 'Hora', value: 'HORA', tag:'v-text', type:'text', cols:'1', rule:'N' },      
                  { text: 'SERIALIZED', value: 'SERIALIZED', tag:'v-text', type:'text', cols:'3', rule:'N', upper:'N' },           
                  { text: 'VALIDO_DATA', value: 'VALIDO_DATA', tag:'v-text', type:'date', cols:'2', width: this.w_data,rule:'N' },      
                  { text: 'VALIDO_HORA', value: 'VALIDO_HORA', tag:'v-text', type:'text', cols:'2', rule:'N' },      
                  { text: 'URL_FILE', value: 'URL_FILE', tag:'v-text', type:'text', cols:'12', rule:'N', upper:'N' },
                  ]

                  // clono os items
                  this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

                  let sql = `SELECT *  FROM `+tabela + ` order by codigo desc` 

                  console.log(tabela+': '+sql);
                  await this.crud_abrir_tabela(sql, 'items_lista')

                  // this.mostrar_rodape = true
                  // await this.somatoria_lista('items_lista', 'QT', 'CODIGO_CLIENTE', 'DSI -')

              }
              
              else if (tabela=='pix_webhooks'){
                
                this.items_header = [

                  { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width: this.w_actions},
                  { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },              
                  { text: 'TOKEN CLIENTE', value: 'TOKEN', tag:'v-text', type:'text', cols:'2', rule:'N' },      
                  { text: 'VISTO', value: 'VISTO', type:'text', tag:'v-select', tag_items:['S','N'],cols:'1',rule:'S'  },            
                  { text: 'Tipo', value: 'TIPO', tag:'v-text', type:'text', cols:'3', rule:'N' },      
                  { text: 'ID', value: 'ID', tag:'v-text', type:'text', cols:'3', rule:'N', upper:'N' },      
                  { text: 'Data', value: 'DATA', tag:'v-text', type:'date', cols:'2', width: this.w_data,rule:'N' },      
                  { text: 'Hora', value: 'HORA', tag:'v-text', type:'text', cols:'2', rule:'N' },        
                  
                  // { text: 'SQL_DESK', value: 'SQL_DESK', tag:'v-text', type:'text', cols:'2', rule:'N', upper:'N' },       
                  
                  { text: 'Comprov.', value: 'x_COMPROVANTE', tag:'v-text', type:'text', cols:'2', rule:'N' },       

                  { text: 'SQL_DESK', value: 'SQL_DESK',tag:'v-textarea', type:'text', cols:'12',rule:'N', width:'150px' },

                  { text: 'Conteudo', value: 'CONTEUDO',tag:'v-textarea', type:'text', cols:'12',rule:'N', width:'150px' },
                  ]

                  // clono os items
                  this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

                  let sql = `SELECT *  FROM `+tabela + ` order by codigo desc` 

                  console.log(tabela+': '+sql);
                  await this.crud_abrir_tabela(sql, 'items_lista')

              }
              
              else if (tabela=='tabela_webhooks'){
                
                this.items_header = [

                  { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width: this.w_actions},
                  { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },              
                  { text: 'ID', value: 'ID', tag:'v-text', type:'text', cols:'3', rule:'N' },      
                  { text: 'CLIENTE', value: 'CODIGO_CLIENTE', tag:'v-text', type:'text', cols:'1', rule:'N' },      
                  { text: 'Tipo', value: 'TIPO', tag:'v-text', type:'text', cols:'2', rule:'N' },      
                  { text: 'Visto', value: 'VISTO', type:'text', tag:'v-select', tag_items:['S','N',],cols:'2',rule:'S'  },      
                  { text: 'Data', value: 'DATA', tag:'v-text', type:'date', cols:'2', width: this.w_data,rule:'N' },      
                  { text: 'Hora', value: 'HORA', tag:'v-text', type:'text', cols:'2', rule:'N' },      
                  { text: 'Plataforma', value: 'PLATAFORMA', tag:'v-text', type:'text', cols:'2', rule:'N' },      
                  { text: 'Celular', value: 'CELULAR', tag:'v-text', type:'text', cols:'2', rule:'N' },  
                  { text: 'Resposta', value: 'RESPOSTA', tag:'v-text', type:'text', cols:'6', rule:'N' },  
                  { text: 'Url Foto', value: 'URL_FOTO', tag:'v-text', type:'text', cols:'2', rule:'N', upper:'N' },  
                  { text: 'Conteudo', value: 'CONTEUDO',tag:'v-textarea', type:'text', cols:'12',rule:'N', width:'150px' },
                  ]

                  // clono os items
                  this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

                  let sql = `SELECT *  FROM `+tabela + ` order by codigo desc` 

                  // console.log(tabela+': '+sql);
                  await this.crud_abrir_tabela(sql, 'items_lista')

              }
              
              
              else if (tabela=='tabela_cobranca'){
                
                this.items_header = [

                  { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width: this.w_actions},
                  // { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },                    
                  { text: 'Plataforma de Cobrança', value: 'PIX_PLATAFORMA',
                      type:'text',
                      tag:'v-select',
                      tag_items:[
                          'ASAAS',         
                          'MERCADOPAGO',
                      ],
                      cols:'4',rule:'N' 
                  },

                  { text: '', value: '', type:'number', cols:'10' }, //em branco                   

                  { text: 'Link da API Teste',          value: 'PIX_LINK_TESTE',tag:'v-text', type:'text', cols:'4',rule:'N', upper:'N' },
                  { text: 'Token Teste',                value: 'PIX_TOKEN_TESTE',tag:'v-text', type:'text', cols:'4',rule:'N', upper:'N' },
                  { text: 'Link WebHook Teste',         value: 'C_LINK_WEBHOOK_TESTE',tag:'v-text', type:'text', cols:'4',rule:'N', upper:'N' },

                  { text: 'Link da API Produção',       value: 'PIX_LINK',tag:'v-text', type:'text', cols:'4',rule:'N', upper:'N' },
                  { text: 'Token Produção',             value: 'PIX_TOKEN',tag:'v-text', type:'text', cols:'4',rule:'N', upper:'N' },
                  { text: 'Link WebHook Produção',      value: 'C_LINK_WEBHOOK',tag:'v-text', type:'text', cols:'4',rule:'N', upper:'N' },

                  ]




                  // clono os items
                  this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

                  // // removo os campos que nao quero visivel somente para edicao
                  this.items_header.splice(this.items_header.findIndex(x => x.value === "PIX_TOKEN_TESTE") , 1);//removo a coluna senha
                  this.items_header.splice(this.items_header.findIndex(x => x.value === "C_LINK_WEBHOOK_TESTE") , 1);//removo a coluna senha

                  this.items_header.splice(this.items_header.findIndex(x => x.value === "PIX_TOKEN") , 1);//removo a coluna senha
                  this.items_header.splice(this.items_header.findIndex(x => x.value === "C_LINK_WEBHOOK") , 1);//removo a coluna senha

    


                  let sql = `SELECT *  FROM `+tabela 

                  // console.log(tabela+': '+sql);
                  await this.crud_abrir_tabela(sql, 'items_lista')

              }

              else if (tabela=='tabela_impressao'){


                this.items_header = [

                    { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width: this.w_actions},

                    { text: 'Descrição', value: 'DESCRICAO', tag:'v-text', type:'text', cols:'4', width: this.w_nome, rule:'S', width:'210px' },
                    // { text: 'Modelo de Impressão', value: 'MODELO_IMPRESSAO', tag:'v-text', type:'text', cols:'4', width: this.w_nome, rule:'N' },
                    { text: 'Modelo de Impressão', value: 'edita_modelo', sortable: false, type:'hide', cols:'0'},
                    { text: 'Logo', value: 'M_LOGO', tag:'v-text', type:'text' },
                    { text: 'Larg.pagina', value: 'M_LARGURA_PAGINA', tag:'v-text', type:'text'  },
                    { text: 'Margem sup.', value: 'M_MARGEM_SUPERIOR', tag:'v-text', type:'text'  },
                    // { text: 'Contrato', value: 'edita_contrato', sortable: false, type:'hide', cols:'0'},
                    
                    
                ]

                // clono os items
                this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

                // removo os campos que nao quero visivel somente para edicao
                // this.items_header.splice(this.items_header.findIndex(x => x.value === "NOTAS") , 1);//removo a coluna senha

                let sql = `select * from ${tabela} `

                  // console.log( tabela+' : '+sql);
                  await this.crud_abrir_tabela(sql,'items_lista')

              }

              else if (tabela=='tabela_servicos'){

                  this.items_header = [

                    { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width: this.w_actions},
                    // { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'2' },
                    { text: 'Descrição', value: 'DESCRICAO', tag:'v-text', type:'text', cols:'6',rule:'S' },

                    { text: 'Categoria', value: 'CATEGORIA',
                        type:'text',
                        tag:'v-select',
                        tag_items:[
                        'A',        
                        'B',     
                        'C',     
                        'D',
                        'E',
                        'AB',     
                        'AC',     
                        'AD',     
                        'AE'      
                        ],
                        cols:'1',rule:'N' 
                    },

                    { text: 'Valor', value: 'double_TOTAL', tag:'v-text', type:'text', cols:'2',rule:'S' },
                    { text: 'Tipo', value: 'NIVEL',
                        type:'text',
                        tag:'v-select',
                        tag_items:[
                            'PRIMARIO',         
                            'SECUNDARIO',     
                            'ASSINATURA',     
                        ],
                        cols:'3',rule:'S' 
                    },
                    { text: 'Contrato', value: 'edita_contrato', sortable: false, type:'hide', cols:'0'},

                  ]

                  this.items_editaveis = this.items_header //campos editaveis sao os mesmos do header

                  let sql = `SELECT *  FROM `+tabela+' order by nivel, descricao'

                //   console.log(tabela+': '+sql);
                await this.crud_abrir_tabela(sql, 'items_lista')
              }

              else if (tabela=='tabela_formas'){

                  this.items_header = [

                    { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width: this.w_actions},
                    // { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'2' },
                    { text: 'Descrição', value: 'DESCRICAO', tag:'v-text', type:'text', cols:'6',rule:'S' },
                    { text: 'Dia Vencimento', value: 'DIA', tag:'v-text', type:'number', cols:'2', rule:'N'},
                    // { text: 'Taxa', value: 'double_TAXA', tag:'v-text', type:'text', cols:'2',rule:'N' },
                    // { text: 'Tipo Taxa', value: 'TIPO_TAXA', tag:'v-select',
                    // tag_items:[
                    //         '%',         
                    //         '$',     
                    //     ],
                    // type:'text', cols:'2',rule:'N' },

                  ]

                  this.items_editaveis = this.items_header //campos editaveis sao os mesmos do header

                  let sql = `SELECT *  FROM `+tabela+' order by descricao'

                  // console.log(tabela+': '+sql);
                  await this.crud_abrir_tabela(sql, 'items_lista')
              }

              else if (tabela=='tabela_contas'){

                  this.items_header = [

                    { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width: this.w_actions},
                    // { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'2' },
                    { text: 'Ativo', value: 'ATIVO', type:'text', tag:'v-select', tag_items:['S','N',],cols:'2',rule:'S'  },      
                    { text: 'Descrição', value: 'DESCRICAO', tag:'v-text', type:'text', cols:'3',rule:'S' },
                    { text: 'Tipo de Chave PIX', value: 'TIPO_CHAVE',
                        type:'text',
                        tag:'v-select',
                        tag_items:[
                            'CPF',         
                            'CNPJ',         
                            'CELULAR',     
                            'EMAIL',     
                        ],
                        cols:'3',rule:'N' 
                    },
                    { text: 'Chave PIX', value: 'CHAVE_PIX', tag:'v-text', type:'text', cols:'3',rule:'N', hint:'', upper:'N' },

                  ]

                  this.items_editaveis = this.items_header //campos editaveis sao os mesmos do header

                  let sql = `SELECT *  FROM `+tabela +' order by descricao'

                //   console.log(tabela+': '+sql);
                await this.crud_abrir_tabela(sql, 'items_lista')
              }
              
              else if (tabela=='tabela_categorias'){


                  await this.monta_items_combobox()

                  this.items_header = [

                    { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width: this.w_actions},
                    // { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'2' },
                    // { text: 'CATEGORIA', value: 'CATEGORIA', tag:'v-text', type:'text', cols:'6',rule:'S' },
                    { text: 'CATEGORIA', value: 'CATEGORIA',
                      type:'text',
                      tag:'v-autocomplete',
                      tag_items: this.items_tabela_categoria,

                      item_value:'CATEGORIA',
                      item_text : 'CATEGORIA',
                      return_object : false,

                      cols:'4',rule:'S' , upper:'S'
                    },

                    { text: 'OPERAÇÃO', value: 'OPERACAO', tag:'v-text', type:'text', cols:'4',rule:'S' },

                  ]

                  this.items_editaveis = this.items_header //campos editaveis sao os mesmos do header

                  let sql = `SELECT *  FROM `+tabela +' order by CATEGORIA'

                //   console.log(tabela+': '+sql);
                await this.crud_abrir_tabela(sql, 'items_lista')
              }
              
              else if (tabela=='instrutores'){

                  this.items_header = [

                    { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width: this.w_actions},
                    // { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'2' },
                    { text: 'Nome', value: 'NOME', tag:'v-text', type:'text', cols:'6',rule:'S' },

                  ]

                  this.items_editaveis = this.items_header //campos editaveis sao os mesmos do header

                  let sql = `SELECT *  FROM `+tabela +' order by nome'

                //   console.log(tabela+': '+sql);
                await this.crud_abrir_tabela(sql, 'items_lista')
              }
              
              else if (tabela=='veiculos'){

                  this.items_header = [

                    { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width: this.w_actions},
                    // { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'2' },

                    { text: 'Descrição', value: 'NOME',tag:'v-text', type:'text', cols:'3',rule:'S' },
                    { text: 'Placa', value: 'PLACA',tag:'v-text', type:'text', cols:'3',rule:'S' },
                    
                    { text: 'Categoria', value: 'CATEGORIA',
                        type:'text',
                        tag:'v-select',
                        tag_items:[
                            'A',         
                            'B',     
                            'C',     
                            'D',
                            'E'
                        ],
                        cols:'2' ,rule:'S'
                    },

                  ]

                  this.items_editaveis = this.items_header //campos editaveis sao os mesmos do header

                  let sql = `SELECT *  FROM `+tabela +' order by categoria, nome'

                //   console.log(tabela+': '+sql);
                await this.crud_abrir_tabela(sql, 'items_lista')
              }
              
              else if ((tabela=='empresa') && (this.qual_janela == 'tabelas')){

                  this.items_header = [

                    { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width: this.w_actions},
                    // { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },
                    { text: 'Nome', value: 'NOME',tag:'v-text', type:'text', cols:'4',rule:'S', width: this.w_nome , upper:'N'},
                    { text: 'Razão Social', value: 'RAZAO_SOCIAL',tag:'v-text', type:'text', cols:'5',rule:'S', width: this.w_nome, upper:'N' },
                    { text: 'CNPJ', value: 'CNPJ',tag:'v-text', type:'text', cols:'3',rule:'S',vmask:'cnpj', width: this.w_cnpj },

                    { text: 'Fone', value: 'FONE1',tag:'v-text', type:'text', cols:'2',rule:'N',vmask:'fone', width: this.w_fone },
                    { text: 'WhatsApp', value: 'CELULAR',tag:'v-text', type:'text', cols:'2',rule:'N',vmask:'cel', width: this.w_fone },
                    { text: 'Email', value: 'EMAIL',tag:'v-text', type:'text', cols:'5',rule:'N' },
                    
                    { text: 'CEP', value: 'CEP',tag:'v-text', type:'text', cols:'3',rule:'N',vmask:'cep' },
                    { text: 'Endereço', value: 'ENDERECO',tag:'v-text', type:'text', cols:'4',rule:'N',upper:'N' },
                    { text: 'Bairro', value: 'BAIRRO',tag:'v-text', type:'text', cols:'3',rule:'N',upper:'N' },
                    { text: 'Cidade', value: 'CIDADE',tag:'v-text', type:'text', cols:'4',rule:'N',upper:'N' },
                    { text: 'UF', value: 'UF',tag:'v-text', type:'text', cols:'1',rule:'N' },
                    
                    // { text: 'Logo', value: 'PATH_LOGO',tag:'file', type:'file', cols:'1',rule:'N' },//nao precisa mostrar
                    { text: 'Logo', value: 'PATH_LOGO',tag:'img', type:'img', cols:'4',rule:'N' },
                    // { text: 'Logo', value: 'PATH_LOGO',tag:'v-text', type:'text', cols:'4',rule:'N' },

                    { text: 'IDE', value: 'IDE',tag:'v-text', type:'text', cols:'1',rule:'N' },
                    // { text: 'IMPORTAR', value: 'IMPORTAR',tag:'v-btn', cols:'1', color:'primary', click:'importar_db' },


                  ]


                  // this.items_editaveis = this.items_header //campos editaveis sao os mesmos do header
                  // clono os items
                  this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array


                  // removo os campos que nao quero visivel somente para edicao
                  this.items_header.splice(this.items_header.findIndex(x => x.value === "PATH_LOGO") , 1);//removo a coluna senha
                  this.items_header.splice(this.items_header.findIndex(x => x.value === "PATH_LOGO") , 1);//removo a coluna senha
                  this.items_header.splice(this.items_header.findIndex(x => x.value === "RAZAO_SOCIAL") , 1);//removo a coluna senha
                  this.items_header.splice(this.items_header.findIndex(x => x.value === "CIDADE") , 1);//removo a coluna senha
                  this.items_header.splice(this.items_header.findIndex(x => x.value === "UF") , 1);//removo a coluna senha
                  this.items_header.splice(this.items_header.findIndex(x => x.value === "CEP") , 1);//removo a coluna senha

                  if (this.souDSi==false){
                    this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "IDE") , 1);//removo a coluna senha
                    // this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "IMPORTAR") , 1);//removo a coluna senha
                  }

                  let sql = `SELECT *  FROM `+tabela 

                  // console.log(tabela+': '+sql);
                await this.crud_abrir_tabela(sql, 'items_lista')

              }

              else if ((tabela=='BD') && (this.qual_janela == 'configuracoes')){
                this.col_menu = 2

                // if (item!==undefined){
                //   this.sub_codigo = item.CODIGO
                //   this.sub_descricao = item.NOME
                // }

                this.items_header = [

                    { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'30px'},
                    { text: 'Parâmetros',tag:'Painel', value: 'PARAM_WHATS', cols:'12' },
                ]

                // clono os items
                this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

                // removo os campos que nao quero visivel somente para edicao
                this.items_header.splice(this.items_header.findIndex(x => x.value === "PARAM_WHATS") , 1);//removo a coluna senha

              }
             
              else if ((tabela=='empresa') && (this.qual_janela == 'configuracoes')){

                  this.items_header = [

                    { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width: this.w_actions},

                    { text: 'Mostrar Taxas e Juros', value: 'C_FIN_VL_SIMPLES',
                        type:'text',
                        tag:'v-select',
                        tag_items:[
                            'NÃO',         
                            'SIM',         
                        ],
                        cols:'2',rule:'N' 
                    },
                    { text: 'Multa %', value: 'MULTA', tag:'v-text', type:'number', cols:'2',rule:'N', hint:'Após o vencimento. Ex. 2%' },
                    { text: 'Juros/dia %', value: 'JUROS', tag:'v-text', type:'text', cols:'2',rule:'N', hint:'Padrão: 0,033% [1/30=juros/dia]' },

                    
                    { text: 'Plataforma de Cobrança', value: 'PIX_PLATAFORMA',
                        type:'text',
                        tag:'v-select',
                        tag_items:[
                            'NENHUM',
                            'ASAAS'
                        ],
                        cols:'2',rule:'N' 
                    },
                    
                    { text: 'Status da Cobrança', value: 'PIX_SITUACAO',
                        type:'text',
                        tag:'v-select',
                        tag_items:[
                            'INATIVO',         
                            'TESTE',         
                            'PRODUÇÃO',
                        ],
                        cols:'2',rule:'N' 
                    },

                    { text: 'Bloquear Após', value: 'C_DIAS_BLOQUEIA', tag:'v-text', type:'number', cols:'2',rule:'N', hint:'Dias de atraso' },

                    { text: 'URL Servidor', value: 'URL_SERVIDOR', tag:'v-text', type:'text', cols:'6',rule:'N', hint:'url usada para os desktops atualizarem', upper:'N' },

                  

                    
                  ]




                  // clono os items
                  this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array


                  // @rex
                  // if (this.souDSi==false){
                  //   this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "PIX_PLATAFORMA") , 1);//removo a coluna senha
                  //   this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "PIX_SITUACAO") , 1);//removo a coluna senha
                  //   this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "C_DIAS_BLOQUEIA") , 1);//removo a coluna senha

                  //   this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "URL_SERVIDOR") , 1);//removo a coluna senha
                    
                  // }

                  let sql = `SELECT *  FROM `+tabela 

                  // console.log(tabela+': '+sql);
                await this.crud_abrir_tabela(sql, 'items_lista')

              }
             
              else if (tabela=='vendas'){

                this.col_menu = 1

                //limpa campos sub
                this.sub_codigo    = 0
                this.sub_descricao = ''

                this.items_header = [

                    { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width: this.w_actions},

                    { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },
                    { text: 'Tipo', value: 'TIPO_CLIENTE',type:'text',tag:'v-select',tag_items:['CLINICA','CFC'],cols:'2', rule:'S'},
                    { text: 'CPF', value: 'CPF',tag:'v-text', type:'text', cols:'2', width: this.w_cpf, rule:'S',vmask:'cpf' },
                    { text: 'Valor', value: 'VALOR', tag:'v-text', type:'text', cols:'2',rule:'S' },
                    { text: 'Nome', value: 'NOME', tag:'v-text', type:'text', cols:'4', width: this.w_nome, rule:'S' },

                    { text: 'Data Compra', value: 'DATA', tag:'v-text', type:'date', cols:'2', width: this.w_data,rule:'S' },
                    { text: 'Efetuado', value: 'EFETUADO',type:'text',tag:'v-select',tag_items:['S','N'],cols:'2', rule:'S'},
                    // { text: 'Data Efetuado', value: 'DATA_EFETUADO', tag:'v-text', type:'date', cols:'2', width: this.w_data, rule:'N' },
                    { text: 'Versão', value: 'VERSAO', tag:'v-text', type:'text', cols:'2',rule:'N' },

                    { text: 'HD', value: 'HD',tag:'v-text', type:'text', cols:'2',rule:'N' },
                    { text: 'COUNT', value: 'COUNT_UPDATE',tag:'v-text', type:'number', cols:'2',rule:'N' },
                    
                    { text: 'Whats', value: 'CELULAR',tag:'v-text', type:'text', cols:'2', width: this.w_fone, rule:'N',vmask:'cel' },
                    { text: 'Email', value: 'EMAIL',tag:'v-text', type:'text', cols:'4',rule:'N' },
                    
                    { text: 'Cidade', value: 'CIDADE',tag:'v-text', type:'text', cols:'3',rule:'N' },
                    { text: 'UF', value: 'UF',tag:'v-text', type:'text', cols:'1',rule:'N' },
                    { text: 'Indicacao', value: 'INDICACAO',tag:'v-text', type:'text', cols:'4',rule:'N' },

                ]

                this.items_editaveis = this.items_header //campos editaveis sao os mesmos do header

                let sql = `select * from ${tabela} order by codigo desc, nome asc`

                  // console.log( tabela+' : '+sql);
                  await this.crud_abrir_tabela(sql,'items_lista')
              }

              else if (tabela=='clientes'){

                this.singe_select = false

                this.col_menu = 1

                //limpa campos sub
                this.sub_codigo    = 0
                this.sub_descricao = ''

                this.items_header = [

                    // { text: 'Codigo', value: 'CODIGO',tag:'v-text', type:'number', cols:'1' },

                    { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width: this.w_actions},
                    { text: 'Users', value: 'actions_usuarios', sortable: false, type:'hide', cols:'0',width:'5px'},
                    { text: 'Assin.', value: 'actions_assinaturas', sortable: false, type:'hide', cols:'0',width:'5px'},
                    { text: 'ChatB', value: 'actions_chatbot', sortable: false, type:'hide', cols:'0',width:'5px'},
                    { text: 'PG',    value: 'actions_parcelas', sortable: false, type:'hide', cols:'0',width:'5px'},

                    { text: 'Codigo', value: 'CODIGO',tag:'v-text', type:'number', cols:'0' },
                    { text: 'Situação', value: 'SITUACAO',type:'text',tag:'v-select',tag_items:['TESTE','ATIVO','AUSENTE','CANCELADO','CONCLUIDO'],cols:'2', rule:'S'},
                    { text: 'BL', value: 'BLOQUEAR',type:'text',tag:'v-select',tag_items:['S','N'],cols:'1', rule:'S'},
                    
                    { text: 'GE', value: 'MES_GEROU',type:'text',tag:'v-select',cols:'1', rule:'N'},
                    { text: 'EF', value: 'MES_PAGOU',type:'text',tag:'v-select',cols:'1', rule:'N'},
                    
                    { text: 'Tipo', value: 'TIPO_CLIENTE',type:'text',tag:'v-select',tag_items:['CFC WEB','CFC','MEDCLINIC','CIVIC','CLINICA','GESTOR','LOCAÇÃO'],cols:'2', rule:'S', width:'90px'},
                    { text: 'Total', value: 'double_VALOR',type:'text',tag:'v-text',cols:'1', rule:'N', width: '100px'},
                    
                    { text: 'Nome', value: 'NOME', tag:'v-text', type:'text', cols:'5', width: this.w_nome, rule:'S', width:'210px' },
                    { text: 'Versão', value: 'VERSAO', tag:'v-text', type:'number', cols:'1',rule:'N' },
                    { text: 'Vence', value: 'DIA_VENCIMENTO',type:'text',tag:'v-text',cols:'1', rule:'S'},

                    { text: 'Token', value: 'TOKEN', tag:'v-text', type:'text', cols:'1',rule:'N' },
                    { text: 'Whats Conectado', value: 'API_WHATS_CONECTADO',type:'text',tag:'v-select',tag_items:['CONNECTED','DISCONNECTED','QRCODE','NOT'],cols:'2', rule:'N'},
                    { text: 'SERIALIZED', value: 'SERIALIZED', tag:'v-text', type:'text', cols:'2',rule:'N' },
                    
                    { text: 'Api_whats_status', value: 'API_WHATS_STATUS', tag:'v-text', type:'text', cols:'1',rule:'N' },
                    

                    { text: 'CPF/CNPJ', value: 'CNPJ_CPF',tag:'v-text', type:'text', cols:'3', width: this.w_cnpj, rule:'S' },
                    { text: 'Data Cadastro', value: 'DATA_CADASTRADO', tag:'v-text', type:'date', cols:'2', width: this.w_data,rule:'S' },

                    
                    // { text: 'Forma', value: 'FORMA_PAGAMENTO',type:'text',tag:'v-select',tag_items:['PIX','BOLETO','DIVERSOS'],cols:'2', rule:'S'},
                  

                    { text: 'Whats', value: 'CELULAR',tag:'v-text', type:'text', cols:'2', width: this.w_fone, rule:'S',vmask:'cel' },
                    { text: 'Email', value: 'EMAIL',tag:'v-text', type:'text', cols:'5',rule:'S' },
                    { text: 'Indicacao', value: 'INDICACAO',type:'text',tag:'v-select',tag_items:['GOOGLE','CLIENTES','WHATS','VENDEDOR','OUTROS'],cols:'2', rule:'N'},

                    
                    
                    
                    { text: 'CEP', value: 'CEP',tag:'v-text', type:'text', cols:'2',rule:'N',vmask:'cep' },
                    { text: 'Endereço', value: 'ENDERECO',tag:'v-text', type:'text', cols:'5',rule:'N' },
                    { text: 'Cidade', value: 'CIDADE',tag:'v-text', type:'text', cols:'3',rule:'N' },
                    { text: 'Bairro', value: 'BAIRRO',tag:'v-text', type:'text', cols:'2',rule:'N' },
                    { text: 'UF', value: 'UF',tag:'v-text', type:'text', cols:'1',rule:'N' },

                    { text: 'Cobrança via Whats', value: 'COBRANCA_WHATS',type:'text',tag:'v-select',tag_items:['S','N'],cols:'2', rule:'N', hint:'Lembrete de Cobrança via Whats'},

                    { text: 'IP Externo', value: 'IP_EXTERNO', tag:'v-text', type:'text', cols:'2',rule:'N', upper:'N' },
                    { text: 'IP Servidor', value: 'IP_SERVIDOR', tag:'v-text', type:'text', cols:'2',rule:'N', upper:'N' },
                    { text: 'Nome Servidor', value: 'NOME_SERVIDOR', tag:'v-text', type:'text', cols:'3',rule:'N', upper:'N' },


                    { text: 'Anotações', value: 'NOTAS',tag:'v-textarea', type:'text', cols:'12',rule:'N' },

                    { text: 'Testar Até', value: 'DATA_TESTE_ACABA', tag:'v-text', type:'date', cols:'2', width: this.w_data,rule:'N' },

                    { text: 'CELULAR_2', value: 'CELULAR_2',tag:'v-text', type:'text', cols:'2', width: this.w_fone, rule:'N',vmask:'cel' },
                    { text: 'CELULAR_2_NOME', value: 'CELULAR_2_NOME',tag:'v-text', type:'text', cols:'2', width: this.w_fone, rule:'N' },
                    { text: 'CELULAR_3', value: 'CELULAR_3',tag:'v-text', type:'text', cols:'2', width: this.w_fone, rule:'N',vmask:'cel' },
                    { text: 'CELULAR_3_NOME', value: 'CELULAR_3_NOME',tag:'v-text', type:'text', cols:'2', width: this.w_fone, rule:'N' },

                    { text: 'URL_AL',    value: 'actions_url_alunos', sortable: false, type:'hide', cols:'0',width:'5px', width: '300px'},

                    
                ]

                // clono os items
                this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

                // removo os campos que nao quero visivel somente para edicao
                this.items_header.splice(this.items_header.findIndex(x => x.value === "NOTAS") , 1);//removo a coluna senha
                this.items_header.splice(this.items_header.findIndex(x => x.value === "ENDERECO") , 1);//removo a coluna senha
                this.items_header.splice(this.items_header.findIndex(x => x.value === "BAIRRO") , 1);//removo a coluna senha
                this.items_header.splice(this.items_header.findIndex(x => x.value === "CEP") , 1);//removo a coluna senha



                this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "MES_GEROU") , 1);//removo a coluna senha
                this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "MES_PAGOU") , 1);//removo a coluna senha
                this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "double_VALOR") , 1);//removo a coluna senha
                this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "TOKEN") , 1);//removo a coluna senha
                this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "API_WHATS_STATUS") , 1);//removo a coluna senha
                this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "API_WHATS_CONECTADO") , 1);//removo a coluna senha
                this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "SERIALIZED") , 1);//removo a coluna senha

                // this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "IP_EXTERNO") , 1);//removo a coluna senha
                // this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "IP_SERVIDOR") , 1);//removo a coluna senha
                // this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "NOME_SERVIDOR") , 1);//removo a coluna senha
              

                let where = ' where (codigo > 0)'
                let situacao = ''
                let tipo     = ''
                
                if (this.tipo_situacao != 'TODOS'){
                    situacao = ` and (SITUACAO = '${this.tipo_situacao}')`
                }
                
                if (this.tipo_cliente != 'TODOS'){
                    tipo = ` and (TIPO_CLIENTE = '${this.tipo_cliente}')`
                }

                where += situacao + tipo

                let sql = `select * from ${tabela} ${where} order by codigo desc, situacao, dia_vencimento , nome`

                // console.log( tabela+' : '+sql);
                await this.crud_abrir_tabela(sql,'items_lista')

                // await this.sum_clientes(this.tipo_situacao)
                // await this.somatoria_lista('items_lista', 'double_VALOR', 'TOTAL')


                this.mostrar_rodape = true

              }

              else if (tabela=='assinaturas'){

                this.col_menu = 1
                

                if (item!==undefined){
                  this.sub_codigo = item.CODIGO
                  this.sub_descricao = item.NOME
                }

                this.items_header = [

                    { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width: this.w_actions},

                    { text: 'Codigo', value: 'CODIGO',tag:'v-text', type:'number', cols:'0' },
                    { text: 'Situação', value: 'SITUACAO',type:'text',tag:'v-select',tag_items:['PROMOVER','ATIVO','TESTE','CANCELADO'],cols:'2', rule:'S', width: this.w_situacao},
                    { text: 'Data', value: 'DATA', tag:'v-text', type:'date', cols:'2', width: this.w_data, rule:'S', hint:'Gerar Cobrança a partir desta Data' },
                    // { text: 'Serviço', value: 'SERVICO', tag:'v-text', type:'text', cols:'4', width: this.w_nome, rule:'S', width:'210px' },


                    { text: 'Serviço', value: 'SERVICO',
                      type:'text',
                      tag:'v-select',
                      tag_items:[
                          'ASSINATURA MEDCLINIC',         
                          'ASSINATURA CFC',         
                          'ASSINATURA GESTOR',         
                          'ASSINATURA CIVIC',         
                          'ASSINATURA DSI',         
                          'MODULO WHATSAPP',         
                          'MODULO COBRANÇA',         
                          'LOCAÇÃO',         
                          'ISENTO',         
                      ],
                      cols:'4',rule:'S' ,width:'290px'
                    },

                    { text: 'Valor', value: 'double_VALOR', tag:'v-text', type:'text', cols:'2',rule:'S', width: this.w_valor, hint:'Quando isento, deixar valor 0' },
                    { text: 'Reajuste', value: 'REAJUSTE',type:'number',tag:'v-text',cols:'2', rule:'N', width: this.w_valor, hint:'em R$'},                   
                    { text: 'Reajustar em', value: 'DATA_REAJUSTAR', tag:'v-text', type:'date', cols:'2', width: this.w_data, rule:'S', hint:'' },

                    { text: 'X_REAJUSTE', value: 'X_REAJUSTE', type:'text', cols:'0', width: this.w_valor },
                    { text: 'ID_USER', value: 'ID_USER', type:'text', cols:'0' },
                    { text: 'Serviço', value: 'SERVICO',tag:'v-text', type:'text', cols:'4',rule:'N', width: '370px' },
                    { text: 'WH', value: 'SALVAR_WEBHOOK',tag:'v-text', type:'text', cols:'2',rule:'N' },

                    { text: 'VALOR_ANTIGO', value: 'double_VALOR_ANTIGO',tag:'v-text', type:'text', cols:'2',rule:'N' },
                    { text: 'ANO_REAJUSTOU', value: 'ANO_REAJUSTOU',tag:'v-text', type:'text', cols:'2',rule:'N' },

                ]


                if (this.sub_codigo ==  0){
                  this.items_header.push(
                    { text: 'IDE', value: 'CODIGO_CLIENTE', type:'number', cols:'2' },
                    { text: 'CLIENTE', value: 'X_NOME', type:'number', cols:'2' },
                    { text: 'TIPO', value: 'X_TIPO_CLIENTE', type:'text', cols:'2' },
                    // { text: 'Situação', value: 'SITUACAO',type:'text',tag:'v-select',tag_items:['PROMOVER','ATIVO','TESTE','CANCELADO'],cols:'2', rule:'S', width: this.w_situacao},
                  )
                }


                // clono os items
                this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

                // removo os campos que nao quero visivel somente para edicao
                this.items_header.splice(this.items_header.findIndex(x => x.value === "SERVICO") , 1);//removo a coluna senha

                let sql   = `` 
                let where = ''
                
                
                if (this.tipo_situacao != 'TODOS'){
                  where = ` where (assinaturas.SITUACAO = '${this.tipo_situacao}') and clientes.SITUACAO = 'ATIVO'`
                }
                else{
                  where = ``
                  // where = `clientes.SITUACAO = 'ATIVO'`
                }
              

                if (this.sub_codigo > 0){

                  sql = `select * from ${tabela} WHERE codigo_cliente = ${this.sub_codigo} order by codigo`

                }
                else{
                  sql = `
                    SELECT 
                      assinaturas.CODIGO,
                      assinaturas.SITUACAO,
                      assinaturas.DATA,
                      assinaturas.CODIGO_CLIENTE,
                      assinaturas.double_VALOR,
                      assinaturas.SERVICO,
                      assinaturas.API_LINK,
                      assinaturas.CELULAR_TESTE,
                      assinaturas.CELULAR_RESPONDER,
                      assinaturas.USAR_LINK_ATENDENDE,
                      assinaturas.REAJUSTE,
                      assinaturas.DATA_REAJUSTAR,
                      assinaturas.ANO_REAJUSTOU,
                      assinaturas.double_VALOR_ANTIGO,
                      assinaturas.SALVAR_WEBHOOK,
                      clientes.NOME as X_NOME,
                      clientes.TIPO_CLIENTE as X_TIPO_CLIENTE
                    FROM
                    assinaturas
                      INNER JOIN clientes ON (assinaturas.CODIGO_CLIENTE=clientes.CODIGO) 
                      ${where}
                    order by  situacao, codigo desc`

                    // order by  situacao, servico, clientes.NOME`
                }


                  // console.log( tabela+' : '+sql);
                  await this.crud_abrir_tabela(sql,'items_lista')


                  // await this.somatoria_lista('items_lista', 'double_VALOR', 'TOTAL')
                  // await this.somatoria_lista('items_lista', 'double_VALOR', 'X_TIPO_CLIENTE')

                  // ESTA FILTRANDO OS VALORES PELA FUNCAO getFiltered associado ao data-table
                  this.mostrar_rodape = true
              }
             
              else if ((tabela=='usuarios') && (this.referencia == 'dsi45')){
                
                // este daqui o cliente podera adicionar ou alterar acessos para seus funcionarios

                this.col_menu = 1

                if (item!==undefined){
                  this.sub_codigo = item.CODIGO
                  this.sub_descricao = item.NOME
                }


                this.items_header.push(
                  { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width: this.w_actions},
                )
                

                if ((this.obj_user.SUPER=='S')){

                  this.items_header.push(
                    
                     { text: 'Ativo', value: 'ATIVO',type:'text',tag:'v-select',tag_items:['S','N'],cols:'1', rule:'S', width: this.w_situacao},
                     { text: 'Cadastrado', value: 'DATA', tag:'v-text', type:'date', cols:'2', width: this.w_data, rule:'S' },
                     { text: '',tag:'span',cols:'9'},//linha ou espaço
                     { text: 'CPF', value: 'CPF',tag:'v-text', type:'text', cols:'2',vmask:'cpf', width: this.w_cpf, rule:'S' },
                  )
                }

                this.items_header.push(
                    
                    { text: 'Usuário', value: 'NOME',tag:'v-text', type:'text', cols:'2',rule:'S',upper:'S',width: '150px', hint:'Nome do Usuario' },
                    { text: 'Senha', value: 'SENHA', tag:'v-text', type:'password', cols:'2',rule:'S', hide:'S' },
                    { text: 'Repetir Senha', value: 'X_SENHA', tag:'v-text', type:'password', cols:'2',rule:'N', hide:'S' },
                    { text: 'Email', value: 'EMAIL',tag:'v-text', type:'text', cols:'4',rule:'S', hint: 'Para recuperação de senha' },

                    { text: 'Online', value: 'ONLINE', type:'text',tag:'v-text',cols:'1',rule:'N'},
                    { text: 'Quando Online', value: 'ONLINE_HORA', type:'text',tag:'v-text',cols:'1',rule:'N'},
                )


                if ((this.obj_user.SUPER=='S')){

                    this.items_header.push(
                      
                      { text: '(Acesso Total)', grupo:'Super', value: 'SUPER', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', class:'red--text font-weight-bold', hint:'Superusuário do Sistema = Acesso Total'},
                      
                    // { text: 'Financeiro',tag:'span',cols:'12'},//linha ou espaço
                      { text: 'Acessar Financeiro', grupo:'Financeiro', value: 'FINANCEIRO_R', type:'text',tag:'v-select',tag_items:['S','N','L'],cols:'2',rule:'S', hint:'(S)im (N)ão (L)imitado'},
                      { text: 'Alterar Financeiro', value: 'FINANCEIRO_U', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', class:'blue--text', hint:''},
                      
                    // { text: 'Tabelas',tag:'span',cols:'12'},//linha ou espaço
                      { text: 'Acessar Tabelas', grupo:'Tabelas', value: 'TABELAS_R', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', hint:''},
                      { text: 'Alterar Tabelas', value: 'TABELAS_U', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', class:'blue--text', hint:''},

                      { text: 'Cadastro',tag:'span',cols:'2'},//linha ou espaço
                      { text: 'Alterar Cadastro', grupo:'Cadastro', value: 'CADASTRO_U', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', class:'blue--text', hint:'Altera Cadastro'},
                      { text: 'Gerar Parcelas', value: 'PARC_GERAR', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', hint:'Pode gerar, efetuar e imprimir recibo'},
                      { text: 'Alterar Parcelas', value: 'PARC_ALTERA', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', class:'blue--text', hint:'Altera parcelas, recebe ou não com juros'},
                      { text: 'Estornar Parcelas', value: 'PARC_ESTORNA', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', class:'red--text', hint:''},
                      { text: 'Excluir Parcelas', value: 'PARC_EXCLUI', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', class:'red--text', hint:''},

                   )
                }


                // clono os items
                this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

                // removo os campos que nao quero visivel somente para edicao
                this.items_header.splice(this.items_header.findIndex(x => x.value === "SENHA") , 1);//removo a coluna senha
                this.items_header.splice(this.items_header.findIndex(x => x.value === "X_SENHA") , 1);//removo a coluna senha

                this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "ONLINE_HORA") , 1);//removo a coluna senha
                this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "ONLINE") , 1);//removo a coluna senha


                // somente ve sua propria senha
                let restrito = ``
                if (this.obj_user.SUPER!='S'){ restrito = ` and (CODIGO = ${this.obj_user.CODIGO})` }

                let AA =  `and (NOME <>'A')`
                if ((this.souDSi)&&(this.superA)){
                   AA = ''
                }

                let sql = `select * from usuarios 
                  where (CODIGO_CLIENTE = ${this.size_bd}) ${AA} ${restrito}
                order by ONLINE_HORA desc, codigo_cliente, cpf, nome`

                  // console.log( tabela+' : '+sql);
                  // await DSicanal.crud_abrir_tabela_45(this, sql,'items_lista')
                  await this.crud_abrir_tabela(sql,'items_lista')
              }
             
              else if ((tabela=='usuarios') && (this.referencia == 'geral')){
                

                this.col_menu = 1

                if (item!==undefined){
                  this.sub_codigo = item.CODIGO
                  this.sub_descricao = item.NOME
                }

                this.items_header = [

                    { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width: this.w_actions},

                    { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },
                    { text: 'Ativo', value: 'ATIVO',type:'text',tag:'v-select',tag_items:['S','N'],cols:'1', rule:'S', width: this.w_situacao},
                    { text: 'Cadastrado', value: 'DATA', tag:'v-text', type:'date', cols:'2', width: this.w_data, rule:'S' },
                    { text: 'CPF', value: 'CPF',tag:'v-text', type:'text', cols:'2',vmask:'cpf', width: this.w_cpf, rule:'S' },
                    
                    { text: 'IDE', value: 'CODIGO_CLIENTE',tag:'v-text', type:'text', cols:'2', width: '20px', rule:'S' },
                    { text: 'CLIENTE', value: 'NOME_CLIENTE',tag:'v-text', type:'text', cols:'2', width: this.w_nome, rule:'S' },

                    { text: 'Usuário', value: 'NOME',tag:'v-text', type:'text', cols:'2',rule:'S',upper:'S',width: '150px' },
                    { text: 'Senha', value: 'SENHA', tag:'v-text', type:'password', cols:'2',rule:'S', hide:'S' },
                    { text: 'Chave', value: 'SENHA', tag:'v-text', type:'text', cols:'2',rule:'S', hide:'S' },
                    // { text: 'Recuperação', value: 'SENHA_RECUPERACAO', tag:'v-text', type:'text', cols:'2',rule:'N', hide:'S' },
                    { text: 'Email', value: 'EMAIL',tag:'v-text', type:'text', cols:'3',rule:'S' },
                    // { text: 'Link', value: 'LINK',tag:'v-text', type:'text', cols:'2',rule:'N',upper:'N' },
                    // { text: 'Chave', value: 'SENHA', tag:'v-text', type:'text', cols:'2',rule:'S', hide:'S' }
                    
                    { text: 'Online', value: 'ONLINE', type:'text',tag:'v-text',cols:'1',rule:'N'},
                    { text: 'Quando Online', value: 'ONLINE_HORA', type:'text',tag:'v-text',cols:'1',rule:'N'},

                    

                        { text: '',tag:'span',cols:'12'},//linha ou espaço quebra de linha
                       { text: '(Acesso Total)', grupo:'Super', value: 'SUPER', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', class:'red--text font-weight-bold', hint:'Superusuário do Sistema = Acesso Total'},
                      
                      // { text: 'Financeiro',tag:'span',cols:'12'},//linha ou espaço
                        { text: 'Acessar Financeiro', grupo:'Financeiro', value: 'FINANCEIRO_R', type:'text',tag:'v-select',tag_items:['S','N','L'],cols:'2',rule:'S', hint:'(S)im (N)ão (L)imitado'},
                        { text: 'Alterar Financeiro', value: 'FINANCEIRO_U', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', class:'blue--text', hint:''},
                        
                      // { text: 'Tabelas',tag:'span',cols:'12'},//linha ou espaço
                        { text: 'Acessar Tabelas', grupo:'Tabelas', value: 'TABELAS_R', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', hint:''},
                        { text: 'Alterar Tabelas', value: 'TABELAS_U', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', class:'blue--text', hint:''},
  
                        { text: 'Cadastro',tag:'span',cols:'2'},//linha ou espaço
                        { text: 'Alterar Cadastro', grupo:'Cadastro', value: 'CADASTRO_U', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', class:'blue--text', hint:'Altera Cadastro'},
                        { text: 'Gerar Parcelas', value: 'PARC_GERAR', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', hint:'Pode gerar, efetuar e imprimir recibo'},
                        { text: 'Alterar Parcelas', value: 'PARC_ALTERA', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', class:'blue--text', hint:'Altera parcelas, recebe ou não com juros'},
                        { text: 'Estornar Parcelas', value: 'PARC_ESTORNA', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', class:'red--text', hint:''},
                        { text: 'Excluir Parcelas', value: 'PARC_EXCLUI', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', class:'red--text', hint:''},
                      
                        
                    // { text: 'Super', value: 'SUPER', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', class:'blue--text', hint:'Superusuário do Sistema = Acesso Total'},
                    // { text: 'Altera Cadastro', value: 'CADASTRO_U', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', hint:'Altera Cadastro'},
                    // { text: 'Acessa Financeiro', value: 'FINANCEIRO_R', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', hint:'Acessa Financeiro'},
                    // { text: 'Altera Financeiro', value: 'FINANCEIRO_U', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', hint:''},
                    // { text: 'Acessa Tabelas', value: 'TABELAS_R', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', hint:''},
                    // { text: 'Altera Tabelas', value: 'TABELAS_U', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', hint:''},

                    // { text: 'Gerar Parcelas', value: 'PARC_GERAR', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', hint:'Pode gerar, efetuar e imprimir recibo'},
                    // { text: 'Altera Parcelas', value: 'PARC_ALTERA', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', hint:'Altera parcelas, recebe ou não com juros'},
                    // { text: 'Estorna Parcelas', value: 'PARC_ESTORNA', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', hint:''},
                    // { text: 'Exclui Parcelas', value: 'PARC_EXCLUI', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', hint:''},
                    
                ]


                // clono os items
                this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

                // removo os campos que nao quero visivel somente para edicao
                this.items_header.splice(this.items_header.findIndex(x => x.value === "SENHA") , 1);//removo a coluna senha
                this.items_header.splice(this.items_header.findIndex(x => x.value === "SENHA") , 1);//removo a coluna chave

                this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "ONLINE_HORA") , 1);//removo a coluna senha
                this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "ONLINE") , 1);//removo a coluna senha
                this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "SENHA_RECUPERACAO") , 1);//removo a coluna senha

                let sql = `select * from usuarios  order by ONLINE_HORA desc, codigo_cliente, cpf, nome`

                  // console.log( tabela+' : '+sql);
                  await this.crud_abrir_tabela(sql,'items_lista')
              }

              else if (tabela=='usuarios'){

                // este daqui visivel quando clica em clientes..somente eu tenho acesso

                this.col_menu = 1

                if (item!==undefined){
                  this.sub_codigo = item.CODIGO
                  this.sub_descricao = item.NOME
                }

                this.items_header = [

                { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width: this.w_actions},

                { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },
                { text: 'Ativo', value: 'ATIVO',type:'text',tag:'v-select',tag_items:['S','N'],cols:'1', rule:'S', width: this.w_situacao},
                { text: 'Cadastrado', value: 'DATA', tag:'v-text', type:'date', cols:'2', width: this.w_data, rule:'S' },
                { text: 'CPF', value: 'CPF',tag:'v-text', type:'text', cols:'2',vmask:'cpf', width: this.w_cpf, rule:'S' },
                { text: 'Email', value: 'EMAIL',tag:'v-text', type:'text', cols:'3',rule:'S' },
                { text: 'Usuário', value: 'NOME',tag:'v-text', type:'text', cols:'2',rule:'S',upper:'S' },
                { text: 'Senha', value: 'SENHA', tag:'v-text', type:'password', cols:'2',rule:'S', hide:'S' },
                // { text: 'Link', value: 'LINK',tag:'v-text', type:'text', cols:'2',rule:'N',upper:'N' },
                // { text: 'Chave', value: 'SENHA', tag:'v-text', type:'text', cols:'2',rule:'S', hide:'S' }

                { text: 'Online', value: 'ONLINE', type:'text',tag:'v-text',cols:'1',rule:'N'},
                { text: 'Online', value: 'ONLINE_HORA', type:'text',tag:'v-text',cols:'1',rule:'N'},



                { text: '',tag:'span',cols:'12'},//linha ou espaço quebra de linha
                { text: '(Acesso Total)', grupo:'Super', value: 'SUPER', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', class:'red--text font-weight-bold', hint:'Superusuário do Sistema = Acesso Total'},
              
              // { text: 'Financeiro',tag:'span',cols:'12'},//linha ou espaço
                { text: 'Acessar Financeiro', grupo:'Financeiro', value: 'FINANCEIRO_R', type:'text',tag:'v-select',tag_items:['S','N','L'],cols:'2',rule:'S', hint:'(S)im (N)ão (L)imitado'},

                { text: 'Alterar Financeiro', value: 'FINANCEIRO_U', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', class:'blue--text', hint:''},
                
              // { text: 'Tabelas',tag:'span',cols:'12'},//linha ou espaço
                { text: 'Acessar Tabelas', grupo:'Tabelas', value: 'TABELAS_R', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', hint:''},
                { text: 'Alterar Tabelas', value: 'TABELAS_U', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', class:'blue--text', hint:''},

                { text: 'Cadastro',tag:'span',cols:'2'},//linha ou espaço
                { text: 'Alterar Cadastro', grupo:'Cadastro', value: 'CADASTRO_U', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', class:'blue--text', hint:'Altera Cadastro'},
                { text: 'Gerar Parcelas', value: 'PARC_GERAR', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', hint:'Pode gerar, efetuar e imprimir recibo'},
                { text: 'Alterar Parcelas', value: 'PARC_ALTERA', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', class:'blue--text', hint:'Altera parcelas, recebe ou não com juros'},
                { text: 'Estornar Parcelas', value: 'PARC_ESTORNA', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', class:'red--text', hint:''},
                { text: 'Excluir Parcelas', value: 'PARC_EXCLUI', type:'text',tag:'v-select',tag_items:['S','N'],cols:'2',rule:'S', class:'red--text', hint:''},
              


                ]


                // clono os items
                this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

                // removo os campos que nao quero visivel somente para edicao
                this.items_header.splice(this.items_header.findIndex(x => x.value === "SENHA") , 1);//removo a coluna senha

                this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "ONLINE_HORA") , 1);//removo a coluna senha
                this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "ONLINE") , 1);//removo a coluna senha

                let sql = `select * from ${tabela} where codigo_cliente = ${this.sub_codigo} order by codigo`

                  // console.log( tabela+' : '+sql);
                  await this.crud_abrir_tabela(sql,'items_lista')
              }

              else if (tabela=='conta'){

                this.col_menu = 1

                if (item!==undefined){
                  this.sub_codigo =   item.CODIGO
                  this.sub_descricao = item.NOME
                }

                this.items_header = [

                    { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width: this.w_actions},

                    { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0',width:'10px' },
                    { text: 'Vencimento', value: 'DATA_VENCIMENTO', tag:'v-text', type:'date', cols:'2', width: this.w_data },
                    { text: 'Efetuado',  value: 'DATA_EFETUADO', tag:'v-text', type:'date', cols:'2', width: this.w_data },
                    // { text: 'Pago',  value: 'PAGO', tag:'v-text', type:'v-text', cols:'1', width: '20px' },
                    { text: 'Pago', value: 'PAGO', type:'text',tag:'v-select',tag_items:['S','N'],cols:'1',rule:'S', class:'blue--text', hint:''},
                    { text: 'Tipo', value: 'TIPO', type:'text',tag:'v-select',tag_items:['E','S'],cols:'1',rule:'S', class:'blue--text', hint:''},

                    
                    { text: 'Valor', value: 'double_VALOR', tag:'v-text', type:'text', cols:'2', width: this.w_valor },
                    { text: 'Taxa', value: 'double_TAXA', tag:'v-text', type:'text', cols:'2', width: this.w_valor },
                    { text: 'Total', value: 'double_TOTAL', tag:'v-text', type:'text', cols:'2', width: this.w_valor },
                    { text: 'Forma', value: 'FORMA_PAGAMENTO', tag:'v-text', type:'text', cols:'2', width: '90px'},

                    { text: 'Referente', value: 'SERVICO_REFERENTE', tag:'v-text', type:'text', cols:'5', width: '400px'},

                    { text: 'Plataforma', value: 'PIX_PLATAFORMA', tag:'v-text', type:'text', cols:'2'},
                    { text: 'Efetuou', value: 'QUEM_EFETUOU', tag:'v-text', type:'text', cols:'2', width: '120px'},
                    
                    
                    
                ]


              // clono os items
              this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

            // removo os campos que nao quero visivel somente para edicao
            // this.items_header.splice(this.items_header.findIndex(x => x.value === "NOTAS") , 1);//removo a coluna senha

                let sql = `select * from ${tabela} where codigo_cliente = ${this.sub_codigo} order by codigo desc`

                  // console.log( tabela+' : '+sql);
                  await this.crud_abrir_tabela(sql,'items_lista')
              }


              this.size_panel()


          },
  
          allUpper(event, field){
              let a = event.target.value;//captura o valor do event
              if (a!==''){  
                  a = a.toUpperCase();
                  this.item_edita[field] = a;
              } 
          },
          
          firstUpper(event, field){
              // console.log(event);
              let a = event.target.value;//captura o valor do event

              if (a!=='' && a.length==1){  
                a = a.toUpperCase();
                // this.item_edita[field] = a;

                event.target.value = a
              } 
          },
  
  
  
          meu_input(event,campo){

                if ((campo.includes('EMAIL')==false) &&
                   (campo.includes('SENHA')==false) &&
                   (campo.includes('SENHA_WEB')==false)){

                    this.item_edita[campo] = this.item_edita[campo].toUpperCase()
                }
          },
          

          meu_keyup(event,campo,upper){
  
            //identifica se o campo é double e muda o display
            if ((campo.includes('double_') || campo.includes('VALOR')) ){
              this.item_edita[campo] =  this.moeda(event)
              // @keyup="editedItem.double_credito = moeda($event)"
            }
            else if (campo.includes('CEP')) {

              this.consulta_cep(this.item_edita[campo])    
            }
            else{
              if ((campo.includes('EMAIL')==false) &&
                (campo.includes('SENHA')==false) &&
                (campo.includes('SENHA_WEB')==false)){

                  if (upper !=='N'){
                    this.allUpper(event, campo)
                  }
                  
              }
            }
          },
  
          editar_impressao(item, tabela, campo, campo_descricao){
              //salvo no sessionStorage pq na session ele nao le quando abre em nova guia
  
            
              // console.log(item);
              
              //mando o codigo do serviço para ser editado lá    
              sessionStorage['editar_impressao'] = 'S'
  
              sessionStorage['print:get_codigo']     = item.CODIGO
              sessionStorage['print:get_tabela']     = tabela
              sessionStorage['print:get_campo']      = campo
              sessionStorage['print:get_descricao']  =  `(${item.CODIGO}) ` + item[campo_descricao] 

              sessionStorage['servico_nome']          = item[campo_descricao]
              sessionStorage['printConteudo']         = item[campo]

              // Transformar o objeto em string e salvar em sessionStorage
              sessionStorage['print:dados_impressao']  =  JSON.stringify(item)
  
              let route = this.$router.resolve({path: '/print'});
              window.open(route.href, '_blank');
  
              this.aguardar_refresh = true
          },


          strtofloat(vem){
             return DSibasico.strtofloat(vem)
          },

          floatTostring(vem){
            return DSibasico.floatTostring(vem)
          },

          async define_data_hora(){
              DSibasico.define_data_hora(this)//passo o this para que la ele defina as variaveis daqui
          },
   
   
      }
  }
  </script>
  
  <style scoped>
    .container{
      max-width: 100%;
    }
  
    .v-tab{
    font-size: 9pt; /* usando o sass-variables */
   }
  
  .count_item {
    position: relative !important;
    top: -5px;
    font-size: 80%;
    vertical-align: super;
    color: red;
  }
  
  .count_item_2 {
    position: relative !important;
    top: 3px;
    font-size: 80%;
    vertical-align: super;
    color: red;
  }

  /* tamanho da fonte do data-table */
.v-data-table >>> tr>td{
  font-size:12px !important;
  /* height: 25px !important; */
}

/* tamanho da fonte do data-table */


.theme--light.v-data-table >>> tbody tr.v-data-table__selected {
    /* seleciona_linha cor */
    background: #eee9e9 !important;
}


.v-data-table >>> td.text-start{ /* padding das colunas data-table  */
      padding-left: 7px !important;
      padding-right: 7px !important;
      /* padding: 0px 2px !important; */
      /* background-color: red; */
}

.v-data-table >>>  th.text-start{ /* padding dos titulos das colunas data-table */
      padding: 0px 4px !important;
      /* background-color: red; */
}


.inline{
  display: inline  !important;
}


.fs-7{
    font-size: 7px !important;
  }
  .fs-8{
    font-size: 8px !important;
  }
  .fs-9{
    font-size: 9px !important;
  }
  .fs-10{
    font-size: 10px !important;
  }
  .fs-11{
    font-size: 11px !important;
  }
  .fs-12{
    font-size: 12px !important;
  }
  .fs-13{
    font-size: 13px !important;
  }
  .fs-14{
    font-size: 14px !important;
  }
  .fs-15{
    font-size: 15px !important;
  }
  .fs-16{
    font-size: 16px !important;
  }
  .fs-17{
    font-size: 17px !important;
  }
  .bold{
    font-weight: bold !important;
  }

  .multi-line-text {
    word-wrap: break-word !important;
  }

</style>



 